<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CONTACT_PHONE_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../../components/thux-list/ThuxListMixin'

import ContactPhoneTable from './ContactPhoneTable'
import ContactPhoneEdit from './ContactPhoneEdit.vue'

export default {
  name: 'ContactPhoneList',
  props: {
    cntId: { required: true }
  },
  mixins: [ThuxListMixin],
  components: {
    'component-table': ContactPhoneTable,
    'component-edit': ContactPhoneEdit
  },
  data () {
    return {
      title: this.$t('Contact phones'),
      rolePerm: ['organization_contactphone_list'],
      actionEnablePermission: ['organization_contactphone_enable'],
      actionDisablePermission: ['organization_contactphone_disable'],
      showHeader: false,
      showCommonFilters: false,
      searchFields: []
    }
  },
  computed: {
    ...mapGetters({
      ...CONTACT_PHONE_TYPES.GENERIC.organization.contactphone.LIST.GETTERS
    }),
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_contactphone_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: CONTACT_PHONE_TYPES.GENERIC.organization.contactphone.LIST.MUTATIONS.setSelectedList,
      setSelectAll: CONTACT_PHONE_TYPES.GENERIC.organization.contactphone.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...CONTACT_PHONE_TYPES.GENERIC.organization.contactphone.LIST.ACTIONS
    }),
    getComponentList () {
      this.setFilters({ filter__contact_id: this.cntId })
    },
    openEditForm (id) {
      this.$set(this.editParams, 'cntId', this.cntId)
      this.$set(this, 'componentId', id)
      this.$set(this, 'showEditForm', true)
      this.$set(this, 'showDetailForm', false)
    }
  }
}
</script>
