<template>
  <b-form>
    <b-form-row>
      <form-thux-horizontal-autocomplete
        :validator="$v.form.security_role"
        label-form="Contact Security Role"
        label-form-class="mb-2"
        class-form="col-lg-6 mb-3"
        labelColsSm="4"
        labelColsLg="2"
        label-search="name"
        :disabled="!formEditableUpdate"
        :value="form.security_role ? { id: form.security_role, name: form.security_role_name } : null"
        :options="securityRoleOptions"
        :placeholder="
          $t('Search or select a {instance}', { instance: $t('contact security role') })
        "
        :min-length="1"
        @search="onSearchSecurityRole"
        @select="selectSecurityRole"
        @clear="clearSecurityRole"
      />
      <form-thux-horizontal-datetime
        :validator="$v.form.date_nomination_sec_role"
        :label-form="'Nomination date' | translate"
        :disabled="!formEditableUpdate"
        class-form="col-lg-4 mb-3"
        labelColsSm="5"
        labelColsLg="3"
        type="date"
        :value="dateNomination"
        :placeholder="$t('Enter the date')"
        @change="dateChange($event, 'date_nomination_sec_role')"
      />
      <b-btn-group class="col-xl-2 btn-horizontal-row-actions mb-2 pl-2">
        <b-btn
          v-if="showAddButton"
          class="mr-1"
          variant="primary"
          :disabled="$v.form.$invalid || !formEditableUpdate"
          @click="addContactSecurityRole"
        >
          <i class="fas fa-plus"></i>
        </b-btn>
        <b-btn class="mr-1" variant="outline-primary" @click="removeContactSecurityRole"
          :disabled="!formEditableUpdate">
          <i :class="index === 0 && contactSecurityRoleLength === 1 ? 'fas fa-eraser' : 'fas fa-minus'"></i>
        </b-btn>
      </b-btn-group>
    </b-form-row>
  </b-form>
</template>

<script>
import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'

import SecurityRolesMixin from '../../mixins/SecurityRolesMixin.vue'

import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
  name: 'OrganizationContactSecurityRolesForm',
  mixins: [ThuxDetailPanelMixin, SecurityRolesMixin],
  props: {
    formUpdate: {},
    index: {},
    contactSecurityRoleLength: {},
    showAddButton: {},
    formEditableUpdate: {},
    organization: undefined,
    contact: undefined
  },
  watch: {
    formUpdate: {
      handler () {
        if (this.formUpdate) {
          this.$set(this, 'form', this.formUpdate)
          if (this.form.date_nomination_sec_role) {
            this.$set(this, 'dateNomination', moment(new Date(this.form.date_nomination_sec_role)))
          }
        }
      },
      deep: true
    }
  },
  data () {
    return {
      form: {},
      dateNomination: undefined
    }
  },
  computed: {},
  methods: {
    init () {
      if (this.formUpdate) {
        this.$set(this, 'form', this.formUpdate)
        if (this.form.date_nomination_sec_role) {
          this.$set(this, 'dateNomination', moment(new Date(this.form.date_nomination_sec_role)))
        }
      }
      this.$watch('form', () => {
        this.$emit('edit-contact-security-role', this.form)
      }, { deep: true })
    },
    removeContactSecurityRole () {
      if (this.index > 0 || this.contactSecurityRoleLength > 1) {
        this.$emit('remove-contact-security-role', this.form)
      } else {
        this.$set(this, 'form', {})
        this.$set(this, 'dateNomination', null)
      }
    },
    addContactSecurityRole () {
      this.$emit('add-contact-security-role', this.$v.form.$model)
    },
    dateChange (evt, fieldName, format = 'YYYY-MM-DD') {
      const date = evt.date
      if (date) {
        this.$set(this.form, fieldName, date.format(format))
      } else {
        this.$set(this.form, fieldName, undefined)
      }
      this.$emit('edit-contact-security-role', this.form)
    },
    selectSecurityRole (item) {
      this.$set(this.form, 'security_role', item.id)
      this.$set(this.form, 'security_role_name', item.name)
      this.$emit('edit-contact-security-role', this.form)
    }
  },
  validations: {
    form: {
      security_role: { required },
      date_nomination_sec_role: { }
    }
  }
}
</script>
