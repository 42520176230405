<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORGANIZATION_CONTACT_TYPES } from '../store'
import { vue } from '../../../../main'

export default {
  name: 'SecurityMixin',
  created () {
    this.setSecurityRoleList({})
  },
  computed: {
    ...mapGetters({
      securityRolelist: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS.securityRoleList
    }),
    securityRoleOptions () {
      if (this.securityRolelist) {
        return this.prepareListForMultiSelect(this.securityRolelist.results, 'id', ['name'])
      }
      return []
    }
  },
  methods: {
    ...mapMutations({
      setSecurityRoleList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.MUTATIONS.setSecurityrolelist
    }),
    ...mapActions({
      getSecurityRoleList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.ACTIONS.getSecurityrolelist
    }),
    onSearchSecurityRole (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 1) {
        const filters = {}
        filters.security_role__icontains = search
        filters.order_by = '-status'
        this.getSecurityRoleList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setSecurityRoleList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    selectSecurityRole (item) {
      this.$set(this.form, 'security_role', item.id)
      this.$set(this.form, 'security_role_name', item.name)
    },
    clearSecurityRole (item) {
      this.$set(this.form, 'security_role', null)
      this.$set(this.form, 'security_role_name', null)
    }
  }
}
</script>
