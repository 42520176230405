<template>
    <div class="nested-form mt-5">
      <b-form>
        <b-form-row >
          <form-thux-horizontal-select
            :validator="$v.form.status"
            :external-errors="errors['status']"
            :label-form="'Status' | translate"
            class-form="col-lg-6 my-2"
            labelColsSm="4"
            labelColsLg="2"
            label-search="text"
            :value="form.status ? { value: form.status, text: form.status_name } : null"
            :placeholder="$t('Select status')"
            :options="documentStatusOptions"
            @select="selectDocumentStatus"
            @clear="clearDocumentStatus"
          />
          <form-thux-horizontal-file
            :validator="$v.form.attachment"
            :label-form="'Attachment' | translate"
            class-form="col-lg-6 my-2"
            labelColsSm="4"
            labelColsLg="2"
            :value="form.attachment"
            :placeholder="$t('Choose a file or drop it here...')"
            :drop-placeholder="$t('Drop file here...')"
            @input="$set(form, 'attachment', $event)"
          />
        </b-form-row>
        <b-form-row>
        <form-thux-horizontal-autocomplete
          :validator="$v.form.document"
          :external-errors="errors['document']"
          :label-form="'Document' | translate"
          label-search="text"
          class-form="col-lg-12 my-2"
          labelColsSm="2"
          labelColsLg="1"
          :value="form.document ? { value: form.document, text: form.document_name } : null"
          :placeholder="$t('Select document')"
          :placeholder-field="form.document_name"
          :options="documentOptions"
          :disabled="disableDocumentField"
          @search="onSearchDocument"
          @select="selectDocument"
          @clear="clearDocument"
        />

        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-datetime
            :validator="$v.form.date_start"
            :external-errors="errors['date_start']"
            :label-form="'Validity start date' | translate"
            class-form="col-lg-6 my-2"
            labelColsSm="4"
            labelColsLg="2"
            type="date"
            :value="docDateStart"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'date_start')"
          />
          <form-thux-horizontal-datetime
            :validator="$v.form.date_end"
            :external-errors="errors['date_end']"
            :label-form="'Validity end date' | translate"
            class-form="col-lg-6 my-2"
            labelColsSm="4"
            labelColsLg="2"
            type="date"
            :value="docDateEnd"
            :min-date="form.date_start"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'date_end')"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="closeForm()">
          {{ 'Cancel' | translate }}
        </b-button>
        <b-button
          variant="primary"
          @click.prevent="onSubmit()"
          :disabled="$v.form.$invalid">
          {{ 'Save' | translate }}
        </b-button>
      </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import { storeMixin } from '@/storeMixin'

import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import DocumentMixin from '../../../document/upload-document/mixins/DocumentMixin.vue'
import DocumentStatusMixin from '../../../document/upload-document/mixins/DocumentStatusMixin'

import { TYPES as UPLOAD_DOCUMENT_TYPES } from '../../../document/upload-document/store'

export default {
  name: 'OrganizationContactUploadDocument',
  mixins: [ThuxDetailPanelMixin, DocumentMixin, DocumentStatusMixin],
  props: ['orgId', 'cntId', 'dateStart', 'dateEnd', 'docType', 'nextMedicalExam'],
  watch: {
    docType: function () {
      this.$set(this, 'docDateStart', undefined)
      this.$set(this, 'docDateEnd', undefined)
      this.$set(this, 'disableDocumentField', false)
      this.$set(this, 'form', {})
      this.init()
    }
  },
  data () {
    return {
      form: {
        organization: this.orgId,
        worker: this.cntId
      },
      docDateStart: null,
      docDateEnd: null,
      selectedDoc: undefined,
      disableDocumentField: false
    }
  },
  computed: {
    ...mapGetters({
      ...UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.DETAIL.GETTERS,
      uploadDocumentList: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.GETTERS.list
    })
  },
  methods: {
    ...mapActions({
      ...UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.DETAIL.ACTIONS,
      setUploadDocumentFilters: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.ACTIONS.setFilters
    }),
    initFields () {
      if (this.docType === 'agree') {
        this.getDocumentList({ filter__status: 1, filter__subtype: 'UNILAV' }).then(
          () => {
            if (this.documentOptions.length === 1) {
              this.$set(this, 'disableDocumentField', true)
              this.selectDocument(this.documentOptions[0])
            } else {
              this.$set(this, 'disableDocumentField', false)
            }
          }
        )
        if (this.dateStart) {
          this.$set(this, 'docDateStart', this.dateStart)
          this.$set(this.form, 'date_start', this.docDateStart.format('YYYY-MM-DD'))
        }
        if (this.dateEnd) {
          this.$set(this, 'docDateEnd', this.dateEnd)
          this.$set(this.form, 'date_end', this.docDateEnd.format('YYYY-MM-DD'))
        }
      }
      if (this.docType === 'med') {
        this.getDocumentList({ filter__status: 1, filter__subtype: 'HS' }).then(
          () => {
            if (this.documentOptions.length === 1) {
              this.$set(this, 'disableDocumentField', true)
              this.selectDocument(this.documentOptions[0])
            } else {
              this.$set(this, 'disableDocumentField', false)
            }
          }
        )
        if (this.nextMedicalExam) {
          this.$set(this, 'docDateEnd', this.nextMedicalExam)
          this.$set(this.form, 'date_end', this.docDateEnd.format('YYYY-MM-DD'))
        }
      }
    },
    selectDocument (item) {
      this.$set(this.form, 'document', item.value)
      this.$set(this.form, 'document_name', item.text)
      this.selectedDoc = this.documentsList.results.find(el => el.id === item.value)
      this.setUploadDocumentFilters({
        filter__document_id: item.value,
        filter__organization_id: this.orgId,
        filter__worker_id: this.cntId,
        filter__is_passed: 'False',
        no_page: 'no_page',
        order_by__: '-id'
      })
    },
    dateChange (evt, dateKey) {
      const date = evt.date
      if (date) {
        this.$set(this.form, dateKey, date.format('YYYY-MM-DD'))
        if (dateKey === 'date_start' && this.selectedDoc && this.selectedDoc.validity_duration) {
          const duration = this.convertStringToDuration(this.selectedDoc.validity_duration)
          if (duration.days || duration.hours) {
            let endDate = date.add(duration.days, 'days')
            endDate = endDate.add(duration.hours, 'hours')
            this.$set(this, 'date_end', endDate)
            this.$set(this.form, 'date_end', endDate.format('YYYY-MM-DD'))
          }
        }
      } else {
        this.$set(this.form, dateKey, undefined)
        this.$set(this, dateKey, undefined)
      }
    },
    convertStringToDuration (string) {
      if (string) {
        const numberOfHours = string.split(':')[0]
        const totalHoursList = numberOfHours.split(' ')
        let days = 0
        let hours = 0
        if (totalHoursList.length === 2) {
          days = parseInt(totalHoursList[0].trim())
          hours = parseInt(totalHoursList[1].trim())
        } else {
          days = Math.floor(parseInt(numberOfHours) / 24)
          const remainder = numberOfHours % 24
          hours = Math.floor(remainder)
        }
        return { days: days, hours: hours }
      }
      return {}
    },
    onSubmit () {
      if (this.uploadDocumentList.length > 0) {
        let formData = {
          organization: this.orgId,
          worker: this.cntId,
          is_from_config: false,
          status: this.form.status,
          attachment: this.form.attachment,
          document: this.form.document
        }
        if (this.form.date_start) {
          formData.date_start = this.form.date_start
        }
        if (this.form.date_end) {
          formData.date_end = this.form.date_end
        }

        if (!this.uploadDocumentList[0].attachment) {
          formData.id = this.uploadDocumentList[0].id
          formData = storeMixin.utils.parseFormMultipart(formData, ['attachment'])
          this.update(formData).then(
            () => {
              this.closeForm()
            }
          )
        } else {
          this.duplicate({ id: this.uploadDocumentList[0].id }).then(
            () => {
              formData.id = this.detail.id
              formData = storeMixin.utils.parseFormMultipart(formData, ['attachment'])
              this.update(formData).then(
                () => {
                  this.closeForm()
                }
              )
            }
          )
        }
      } else {
        let formData = {
          organization: this.orgId,
          worker: this.cntId,
          is_from_config: false,
          status: this.form.status,
          attachment: this.form.attachment,
          document: this.form.document
        }
        if (this.form.date_start) {
          formData.date_start = this.form.date_start
        }
        if (this.form.date_end) {
          formData.date_end = this.form.date_end
        }
        formData = storeMixin.utils.parseFormMultipart(formData, ['attachment'])
        this.create(formData).then(
          () => {
            this.closeForm()
          }
        )
      }
    }
  },
  validations: {
    form: {
      status: { required },
      attachment: { required },
      document: { required },
      worker: { required },
      organization: { required },
      date_start: { },
      date_end: { }
    }
  }

}
</script>
