<template>
  <thux-table
    :instance="instance"
    :instance-name="instanceName"
    :order-by-dict="orderByDict"
    :goBack="goBack"
    :list="list"
    :fieldName="fieldName"
    :fields="fields"
    :fields-column="fieldsColumn"
    :select-all="selectAll"
    :selected-list="selectedList"
    :get-local-selected-all="getLocalSelectedAll"
    :local-selected-list="localSelectedList"
    @check-all="checkAll"
    @check-none="checkNone"
    @set-selected-row="setSelectedRow"
    :show-checks="showChecks"
    :show-edit-button="showEditButton"
    @change-status="changeInstanceStatus"
    @sort-changed="orderTable"
    @remove-order-by="removeOrderFromTable"
    @open-edit-form="$emit('open-edit-form', $event)"
    @open-detail-form="$emit('open-detail-form', $event)"
  >
    <template slot="roles" slot-scope="row">
      <div v-if="row.item.roles">
        <span
          v-for="role in row.item.roles"
          :key="role.id"
          class="tag"
        >
          {{ role.name }}
        </span>
      </div>
    </template>
    <template slot="security_roles" slot-scope="row">
      <div v-if="row.item.security_roles">
        <span
          v-for="role in row.item.security_roles"
          :key="role.id"
          class="tag"
        >
          {{ role.security_role.name }}
        </span>
      </div>
    </template>
    <template slot="organization_type_names" slot-scope="row">
      <div v-if="row.item.organization_type_names">
        <span
          v-for="role in row.item.organization_type_names"
          :key="role"
          class="tag"
        >
        <span>
          {{ role }}
        </span>
        </span>
      </div>
    </template>
    <template slot="date_next_medical_exam" slot-scope="row">
        <span :class="isDatePassed(row.item.date_next_medical_exam) ? 'text-red': '' ">{{ row.item.date_next_medical_exam | date}}</span>
    </template>
    <template slot="contract_end" slot-scope="row">
        <span :class="isDatePassed(row.item.contract_end) ? 'text-red': '' ">{{ row.item.contract_end | date}}</span>
    </template>
      <template slot="other-buttons-actions-bottom" slot-scope="row">
        <b-btn
          variant="outline-primary"
          v-has-perms="printWorkerCardRolePerm"
          :title="'print card' | translate"
          @click="printWorkerCard(row.item)"
        >
          <i class="fas fa-id-card"></i>
        </b-btn>
        <b-btn
          variant="outline-primary"
          v-has-perms="printDPIDeliveryRolePerm"
          :title="'print dpi delivery' | translate"
          @click="printDPIDelivery(row.item)"
        >
          <i class="fas fa-file-pdf"></i>
        </b-btn>
      </template>
  </thux-table>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORGANIZATION_CONTACT_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxTableMixin from '../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'

export default {
  name: 'OrganizationContactTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  data () {
    return {
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'organization__name',
          label: this.$t('Organization'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          condition: 'organization',
          value: 'name'
        },
        {
          key: 'organization_type_names',
          label: this.$t('Organization type'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: false,
          custom: true
        },
        {
          key: 'staff_type__name',
          label: this.$t('Staff type'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          condition: 'staff_type',
          value: 'name'
        },
        {
          key: 'roles',
          label: this.$t('Roles'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'qualification_str',
          label: this.$t('Qualification'),
          tdClass: 'text-wrap',
          thClass: 'text-wrap',
          value: 'qualification_str'
        },
        {
          key: 'security_roles',
          label: this.$t('Security roles'),
          tdClass: 'text-wrap',
          thClass: 'text-wrap',
          custom: true
        },
        {
          key: 'date_next_medical_exam',
          label: this.$t('Date next medical exam'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'contract_type__name',
          label: this.$t('Contract typology'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          condition: 'contract_type',
          value: 'name'
        },
        {
          key: 'contract_end',
          label: this.$t('Contract end'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          // type: 'date',
          sortable: true,
          custom: true
          // value: 'contract_end'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: [
        'status', 'organization__name', 'staff_type__name', 'contract_type__name',
        'contract_start', 'contract_end', 'job_risk__name', 'work_place'
      ],
      // Permissions
      printWorkerCardRolePerm: 'organization_organizationcontact_print_card_pdf',
      printDPIDeliveryRolePerm: 'organization_organizationcontact_print_dpi_delivery_pdf'
    }
  },
  computed: {
    ...mapGetters({
      selectAll: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS.selectAll,
      selectedList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showEditButton () {
      return hasPerm('organization_organizationcontact_update')
    }
  },
  methods: {
    ...mapMutations({
      setList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.MUTATIONS.setList,
      setSelectedList:
        ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.ACTIONS
    }),
    isDatePassed (date) {
      if (date) {
        if (new Date() > new Date(date)) {
          return true
        }
      }
      return false
    },
    printWorkerCard (item) {
      const fileName = `${item.contact_last_name}_${item.contact_first_name}_${item.organization.name}.pdf`
      this.download({ id: item.id, action: 'print-card-pdf', simpleFileName: fileName })
    },
    printDPIDelivery (item) {
      const fileName = `DPI_${item.contact_last_name}_${item.contact_first_name}_${item.organization.name}.pdf`
      this.download({ id: item.id, action: 'print-dpi-delivery-pdf', simpleFileName: fileName })
    }
  }
}
</script>
