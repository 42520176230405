<template>
  <div class="panel-detail panel-fixed" v-if="!isDocumentsRoute">
    <div class="panel-header">
      <h1 v-if="detail.last_name || detail.first_name" class="page-header org-detail-logo">
        <img class="mr-3" v-if='oldPhoto' :src='oldPhoto'/>
        {{ detail.last_name }} {{ detail.first_name }} {{ organizationName ? ` - ${organizationName}` : ''}}
        <div class="page-header-actions ml-2">
          <b-btn
            v-if="showDocumentsButton"
            variant="outline-primary"
            v-has-perms="showContactDocumentsRolePerm"
            :title="'Show contact documents' | translate"
            :to="getContactDocumentsRouter(`${detail.last_name} ${detail.first_name}`)"
          >
            <i class="far fa-file-alt"></i>
          </b-btn>
          <b-btn
            class="ml-2"
            v-if="showSubscribeToCoursesButton"
            variant="outline-primary"
            v-has-perms="subscribeToCoursesRolePerm"
            :title="'Subscribe to courses' | translate"
            @click.prevent="openSubscribeToCourseModal()"
          >
            <i class="fas fa-graduation-cap"></i>
          </b-btn>
        </div>
      </h1>
      <ol class="breadcrumb pull-right">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard'}">
            {{"Dashboard"}}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <span v-if="orgId" @click="goToOrganizationDetail" class="underline-on-hover">
            {{ organizationTitle | translate }}
          </span>
          <span v-else @click="goToList" class="underline-on-hover">
            {{ titleList | translate }}
          </span>
        </li>
        <li class="breadcrumb-item">
          <span>
            {{ title | translate }}
          </span>
        </li>
      </ol>
    </div>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <div v-if="detail.last_name || detail.first_name">
      <contact-detail-form
        :id="cntId"
        :form-initial="form"
        :photo-placeholder="photoPlaceholder"
        :orgType="orgType"
        :orgId="orgId"
        :errors="errors"
        @update-contact="onSubmit"
        @close-form="$emit('close-form')"
      />
      <duplicated-contact-modal
        :cntId="cntId"
        v-if="showDuplicatedForm"
        v-on:close-modal="closeModal"
        :org-type="orgType"
        v-on:close-modal-and-change="goToDetailPage"
        v-on:close-modal-and-add-contact="addContactToOrganization"
        :cntFilters="cntFilters"
      ></duplicated-contact-modal>
      <organization-contact-edit
        v-if="showOrgContactEdit && cntId && orgId"
        :photo-placeholder="photoPlaceholder"
        :org-id="orgId"
        :cnt-id="cntId">
      </organization-contact-edit>
      <organization-contact-list v-else-if="cntId" :is-detail=true :cnt-id="cntId"></organization-contact-list>
      <contact-phone-list v-if="cntId" :is-detail=true :cnt-id="cntId"></contact-phone-list>
      <contact-email-list v-if="cntId" :is-detail=true :cnt-id="cntId"></contact-email-list>
      <contact-address-list v-if="cntId" :is-detail=true :cnt-id="cntId"></contact-address-list>
      <subscribe-to-course-modal
        v-if="showSubscribeToCourseModal"
        :contact="detail"
        :orgId="orgId"
        v-on:close-modal="closeSubscribeToCourseModal"
        v-on:close-modal-and-subscribe="subscribeContactToCourse"
      >
      </subscribe-to-course-modal>
      <!-- <contact-social-account-list v-if="cntId" :is-detail=true :cnt-id="cntId"></contact-social-account-list> -->
    </div>
  </div>
  <div v-else>
    <router-view class="panel-detail panel-fixed"/>
  </div>
</template>

<script>
import ContactDetailForm from '../ContactDetailForm'
import OrganizationContactEdit from '../../../organization-contact/OrganizationContactEdit'
import ContactPhoneList from '../../../contact/contact-phone/ContactPhoneList'
import ContactEmailList from '../../../contact/contact-email/ContactEmailList'
import ContactAddressList from '../../../contact/contact-address/ContactAddressList'
// import ContactSocialAccountList from '../../../contact/contact-social-account/ContactSocialAccountList'
import DuplicatedContactModal from '../contact-modal/DuplicatedContactModal'
import SubscribeToCourseModal from '../contact-modal/SubscribeToCourseModal'
import OrganizationContactList from '../../../organization-contact/OrganizationContactList'
import { ROUTES as SUBJECT_CONTACT_ROUTES } from '../../../contact/contact/subject-contact/router'
import { TYPES as CONTACT_TYPES } from '../../../contact/store'
import {
  ORGANIZATION_CUSTOMER_LEAD_CODE,
  ORGANIZATION_CUSTOMER_TYPE_CODE,
  ORGANIZATION_SUPPLIER_TYPE_CODE,
  ORGANIZATION_OWNER_TYPE_CODE,
  ORGANIZATION_CUSTOMER_CODE,
  ORGANIZATION_SUPPLIER_CODE,
  ORGANIZATION_OWNER_CODE
} from '../../../../../const'
import { ROUTES as CUSTOMER_CONTACT_ROUTES } from '../../../contact/contact/customer-contact/router'
import { ROUTES as SUPPLIER_CONTACT_ROUTES } from '../../../contact/contact/supplier-contact/router'
import { ROUTES as LEAD_CONTACT_ROUTES } from '../../../contact/contact/lead-contact/router'
import { ROUTES as CUSTOMER_ORG_ROUTES } from '../../../organization/organization/customer-organization/router'
import { ROUTES as SUPPLIER_ORG_ROUTES } from '../../../organization/organization/supplier-organization/router'
import { ROUTES as OWNER_ORG_ROUTES } from '../../../organization/organization/owner-organization/router'
import { ROUTES as LEAD_ORG_ROUTES } from '../../../organization/organization/lead-organization/router'
import { mapActions } from 'vuex'

export default {
  name: 'ContactDetailMixin',
  components: {
    ContactDetailForm,
    OrganizationContactEdit,
    OrganizationContactList,
    ContactPhoneList,
    ContactEmailList,
    ContactAddressList,
    // ContactSocialAccountList,
    DuplicatedContactModal,
    SubscribeToCourseModal
  },
  data () {
    return {
      organizationTitle: '',
      showOrgContactEdit: false,
      routeNameOrganizationDetail: '',
      title: '',
      titleList: '',
      organizationName: null,
      orgType: null,
      orgId: this.$route.params.id,
      cntId: this.$route.params.cntId,
      form: {},
      oldPhoto: undefined,
      photoPlaceholder: undefined,
      showDuplicatedForm: false,
      cntFilters: undefined,
      ORGANIZATION_CUSTOMER_CODE,
      ORGANIZATION_SUPPLIER_CODE,
      ORGANIZATION_CUSTOMER_LEAD_CODE,
      ORGANIZATION_OWNER_CODE,
      orgCode: null,
      showSubscribeToCoursesButton: true,
      showSubscribeToCourseModal: false,
      // Permissions
      showContactDocumentsRolePerm: 'document_uploaddocument_list',
      subscribeToCoursesRolePerm: 'organization_contact_subscribe_training'
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions({
      subscribeToCourse: CONTACT_TYPES.GENERIC.organization.contact.DETAIL.ACTIONS.subscribeToCourse
    }),
    init () {
      this.retrieve({ id: this.cntId }).then(() => {
        const form = Object.assign({}, this.detail)
        if (form.photo) {
          this.oldPhoto = form.photo
          this.photoPlaceholder = form.photo_filename
        }
        form.photo = undefined
        if (this.orgId && form.organizations && form.organizations.length > 0) {
          const organization = form.organizations.find((organization) => {
            if (organization && organization.id && this.orgId) {
              return organization.id.toString() === this.orgId.toString()
            }
            return false
          })
          if (organization) this.$set(this, 'organizationName', organization.name)
        }
        delete (form.organization_contacts)
        this.$set(this, 'form', form)
        this.showOrgContactEdit = true
      })
    },
    onSubmit (form) {
      const formData = Object.assign({}, form.data)
      const res = []
      if (formData.organization_contacts_security_roles && formData.organization_contacts_security_roles.length > 0) {
        formData.organization_contacts_security_roles.forEach(el => {
          if (el instanceof Object) {
            res.push(el.id)
          } else {
            res.push(el)
          }
        })
        this.$set(formData, 'organization_contacts_security_roles', res)
      }
      this.update(formData).then(() => {
        this.$set(this, 'form', this.detail)
        if (this.form.photo) {
          this.oldPhoto = this.form.photo
          this.photoPlaceholder = this.form.photo_filename
          delete this.form.photo
        }
        this.$set(this, 'showOrgRowEdit', true)
      }).catch((error) => {
        this.cntFilters = {
          last_name: formData.last_name,
          first_name: formData.first_name
        }
        if (formData.ssn) {
          this.cntFilters.ssn = formData.ssn
        }
        if (formData.vat_number) {
          this.cntFilters.vat_number = formData.vat_number
        }
        if ('duplicated_contact' in error.response.data) {
          this.showDuplicatedForm = true
        }
      })
    },
    closeModal () {
      this.cntFilters = false
      this.showDuplicatedForm = false
    },
    goToDetailPage (evt) {
      if (evt.orgTypes.length === 0) {
        this.$router.push({ name: SUBJECT_CONTACT_ROUTES.SUBJECT_CONTACT_DETAIL, params: { cntId: evt.cntId } })
      } else if (evt.orgTypes.includes(ORGANIZATION_CUSTOMER_LEAD_CODE)) {
        this.$router.push({ name: LEAD_CONTACT_ROUTES.LEAD_CONTACT_DETAIL, params: { cntId: evt.cntId, orgType: ORGANIZATION_CUSTOMER_LEAD_CODE } })
      } else if (evt.orgTypes.includes(ORGANIZATION_CUSTOMER_TYPE_CODE)) {
        this.$router.push({ name: CUSTOMER_CONTACT_ROUTES.CUSTOMER_CONTACT_DETAIL, params: { cntId: evt.cntId } })
      } else if (evt.orgTypes.includes(ORGANIZATION_SUPPLIER_TYPE_CODE)) {
        this.$router.push({ name: SUPPLIER_CONTACT_ROUTES.SUPPLIER_CONTACT_DETAIL, params: { cntId: evt.cntId } })
      }
      this.closeModal()
    },
    addContactToOrganization (evt) {
      this.createOrganizationContact({ organization: this.orgId, contact: evt.cntId }).then(
        () => {
          this.getContactList()
          this.$emit('close-form')
        }
      )
      this.closeModal()
    },
    goToOrganizationDetail () {},
    goToList () {},
    getContactDocumentsRouter (name) {
      const { cntId, orgId, orgType, orgCode } = this
      if (orgId) {
        if (orgType === ORGANIZATION_OWNER_TYPE_CODE) {
          console.log(OWNER_ORG_ROUTES.OWNER_ORGANIZATION_CONTACT_DOCUMENTS)
          return {
            name: OWNER_ORG_ROUTES.OWNER_ORGANIZATION_CONTACT_DOCUMENTS,
            params: { orgId, cntId, orgType: ORGANIZATION_OWNER_CODE, cntName: name }
          }
        }
        if (orgType === ORGANIZATION_SUPPLIER_TYPE_CODE) {
          return {
            name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_CONTACT_DOCUMENTS,
            params: { orgId, cntId, orgType: ORGANIZATION_SUPPLIER_CODE, cntName: name }
          }
        } else if (orgCode === ORGANIZATION_CUSTOMER_LEAD_CODE) {
          return {
            name: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_CONTACT_DOCUMENT_LIST,
            params: { orgId, cntId, orgType: ORGANIZATION_CUSTOMER_LEAD_CODE, cntName: name }
          }
        } else {
          return {
            name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_CONTACT_DOCUMENTS,
            params: { orgId, cntId, orgType: ORGANIZATION_CUSTOMER_CODE, cntName: name }
          }
        }
      } else if (orgType && orgType === ORGANIZATION_SUPPLIER_TYPE_CODE) {
        return { name: SUPPLIER_CONTACT_ROUTES.SUPPLIER_CONTACT_DOCUMENT_LIST, params: { orgType: ORGANIZATION_SUPPLIER_CODE, cntId, cntName: name } }
      } else if (orgType && orgType === ORGANIZATION_CUSTOMER_TYPE_CODE && orgCode === ORGANIZATION_CUSTOMER_LEAD_CODE) {
        return { name: LEAD_CONTACT_ROUTES.LEAD_CONTACT_DOCUMENT_LIST, params: { orgType: orgCode, cntId, cntName: name } }
      } else {
        return { name: CUSTOMER_CONTACT_ROUTES.CUSTOMER_CONTACT_DOCUMENT_LIST, params: { orgType: ORGANIZATION_CUSTOMER_CODE, cntId, cntName: name } }
      }
    },
    openSubscribeToCourseModal () {
      this.showSubscribeToCourseModal = true
    },
    closeSubscribeToCourseModal () {
      this.showSubscribeToCourseModal = false
    },
    subscribeContactToCourse (evt) {
      console.log(evt)
      const formData = {}
      formData.id = evt.contact
      formData.organization_id = evt.organization
      formData.max_date = evt.max_date
      formData.sku = []
      evt.products.forEach(el => {
        formData.sku.push(el.sku)
      })
      this.subscribeToCourse(formData).then(
        () => {
          this.showSubscribeToCourseModal = false
        }
      )
    }
  },
  computed: {
    isDocumentsRoute () {
      return [
        SUPPLIER_CONTACT_ROUTES.SUPPLIER_CONTACT_DOCUMENT_LIST,
        LEAD_CONTACT_ROUTES.LEAD_CONTACT_DOCUMENT_LIST,
        CUSTOMER_CONTACT_ROUTES.CUSTOMER_CONTACT_DOCUMENT_LIST
      ].includes(this.$route.name)
    },
    showDocumentsButton () {
      return true
    }
  }
}
</script>
