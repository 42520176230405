<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ThuxListMixin from '@/components/thux-list/ThuxListMixin'
import { TYPES as ORGANIZATION_CONTACT_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import OrganizationContactTable from './OrganizationContactTable'
import OrganizationContactEdit from './OrganizationContactEdit'

export default {
  name: 'OrganizationContactList',
  props: ['cntId'],
  mixins: [ThuxListMixin],
  components: {
    'component-table': OrganizationContactTable,
    'component-edit': OrganizationContactEdit
  },
  data () {
    return {
      title: this.$t('Organization Contacts'),
      rolePerm: ['organization_organizationcontact_list'],
      actionEnablePermission: ['organization_organizationcontact_enable'],
      actionDisablePermission: ['organization_organizationcontact_disable'],
      showHeader: false,
      showCommonFilters: false,
      searchFields: [
        { key: 'filter__organization__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 },
        {
          key: 'filter__status',
          placeholder: this.$t('Status'),
          type: 'boolean',
          options: [
            { value: 1, text: this.$t('ENABLED') },
            { value: 0, text: this.$t('DISABLED') }
          ],
          col: 6,
          initialValue: 1
        }
      ],
      detailComponent: false
    }
  },
  computed: {
    ...mapGetters({
      ...ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS
    }),
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_organizationcontact_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.ACTIONS
    }),
    getComponentList () {
      this.setFilters({ filter__contact_id: this.cntId, filter__status: 1 })
    },
    openEditForm (id) {
      this.$set(this.editParams, 'cntId', this.cntId)
      this.$set(this, 'componentId', id)
      this.$set(this, 'showEditForm', true)
      this.$set(this, 'showDetailForm', false)
    }
  }
}
</script>
