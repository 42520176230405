<template>
  <div>
    <div class="col-12 installment-items px-0" v-for="(contactSecurityRole, index) in contactSecurityRoles" :key="index">
      <organization-contact-security-roles-form
        :form-editable-update="formEditableUpdate"
        :form-update="contactSecurityRole"
        :show-add-button="index === contactSecurityRoles.length - 1"
        :contact-security-role-length="contactSecurityRoles.length"
        :index="index"
        :organization="organization"
        :contact="contact"
        @add-contact-security-role="addNewContactSecurityRole"
        @edit-contact-security-role="editContactSecurityRole($event, index)"
        @remove-contact-security-role="removeContactSecurityRole($event, index)"
      />
    </div>
  </div>
</template>

<script>
import OrganizationContactSecurityRolesForm from './OrganizationContactSecurityRolesForm'
import { cloneDeep } from 'lodash'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'OrganizationContactSecurityRolesManager',
  props: {
    formEditableUpdate: {},
    orgCntSecurityRoles: [],
    organization: undefined,
    contact: undefined,
    resetContactSecurityRoles: {}
  },
  components: {
    OrganizationContactSecurityRolesForm
  },
  watch: {
    orgCntSecurityRoles () {
      this.init()
    },
    resetContactSecurityRoles () {
      this.init()
    },
    contactSecurityRoles: {
      handler () {
        let contactSecurityRoles = [...this.contactSecurityRoles]
        contactSecurityRoles = contactSecurityRoles.filter((secRole) => {
          return Object.values(secRole).some((value) => { return !!value })
        })
        this.$emit(
          'update-security-roles',
          contactSecurityRoles,
          this.securityRolesToDelete,
          this.securityRolesToChange
        )
      },
      deep: true
    }
  },
  data () {
    return {
      contactSecurityRoles: [{}],
      securityRolesToDelete: new Set(),
      securityRolesToChange: new Set()
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.orgCntSecurityRoles && this.orgCntSecurityRoles.length > 0) {
        this.$set(this, 'contactSecurityRoles', cloneDeep(this.orgCntSecurityRoles))
      } else {
        this.$set(this, 'contactSecurityRoles', [{}])
      }
    },
    addNewContactSecurityRole () {
      this.contactSecurityRoles.push({ organization: this.organization, contact: this.contact })
    },
    editContactSecurityRole (contactSecurityRole, index) {
      if (contactSecurityRole.security_role_name && !contactSecurityRole.organization) {
        contactSecurityRole.organization = this.organization
      }
      if (contactSecurityRole.security_role_name && !contactSecurityRole.contact) {
        contactSecurityRole.contact = this.contact
      }
      if (contactSecurityRole.id) {
        this.securityRolesToChange.add(contactSecurityRole.id)
      }
      this.$set(this.contactSecurityRoles, index, contactSecurityRole)
    },
    removeContactSecurityRole (contactSecurityRole, index) {
      if (contactSecurityRole.id) {
        this.securityRolesToDelete.add(contactSecurityRole.id)
        this.securityRolesToChange.delete(contactSecurityRole.id)
      }
      this.contactSecurityRoles.splice(index, 1)
    }
  },
  validations: {
    contactSecurityRoles: {
      $each: {
        security_role: { required },
        contact: { required },
        organization: { required },
        date_nomination_sec_role: { }
      }
    }
  }

}
</script>
