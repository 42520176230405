<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ORGANIZATION_CONTACT_TYPES } from '../store'

export default {
  name: 'ContractTypeMixin',
  created () {
    if (!this.contractTypeList || (this.contractTypeList && this.contractTypeList.length === 0)) {
      this.getContractTypeList()
    }
  },
  computed: {
    ...mapGetters({
      contractTypeList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS.contractTypeList
    }),
    contractTypeListOptions () {
      const options = []
      if (this.contractTypeList && this.contractTypeList.length > 0) {
        this.contractTypeList.forEach((contractType) => {
          const option = {
            id: contractType.id,
            name: contractType.name,
            status: contractType.status
          }
          if (contractType.status === 0) {
            option.$isDisabled = true
          }
          options.push(option)
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getContractTypeList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.ACTIONS.getContracttypelist
    }),
    selectContractType (item) {
      this.$set(this.form, 'contract_type', item.id)
      this.$set(this.form, 'contract_type_name', item.name)
    },
    clearContractType () {
      this.$set(this.form, 'contract_type', null)
      this.$set(this.form, 'contract_type_name', null)
    }
  }
}
</script>
