<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ORGANIZATION_CONTACT_TYPES } from '../store'

export default {
  name: 'RolesMixin',
  created () {
    if (!this.roles || (this.roles && this.roles.length === 0)) {
      this.getRolesList()
    }
  },
  computed: {
    ...mapGetters({
      roles: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS.roles
    }),
    rolesOptions () {
      const options = []
      if (this.roles && this.roles.length > 0) {
        this.roles.forEach((role) => {
          const option = {
            id: role.id,
            name: role.name,
            status: role.status
          }
          if (role.status === 0) {
            option.$isDisabled = true
          }
          options.push(option)
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getRolesList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.ACTIONS.getRoles
    }),
    getRoles (form) {
      const values = []
      if (form.roles) {
        form.roles.forEach(element => {
          values.push({
            id: element.id,
            name: element.name,
            status: element.status
          })
        })
        return values
      }
    },
    setRoles (formData) {
      if (this.form.roles) {
        const list = []
        this.form.roles.forEach((row) => list.push(row.id))
        this.$set(formData, 'roles', new Set(list))
      } else {
        this.$set(formData, 'roles', [])
      }
      return formData
    },
    selectRole (item) {
      if (!this.form.roles) {
        this.$set(this.form, 'roles', [])
      }
      this.form.roles.push(item)
    },
    removeRole (item) {
      const roles = this.form.roles.filter((role) => {
        return role.id !== item.id
      })
      this.$set(this.form, 'roles', roles)
      if (this.form.roles.length === 0) this.$set(this.form, 'roles', null)
    }
  }
}
</script>
