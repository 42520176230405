<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ORGANIZATION_CONTACT_TYPES } from '../store'

export default {
  name: 'StaffTypeMixin',
  created () {
    if (!this.staffTypeList || (this.staffTypeList && this.staffTypeList.length === 0)) {
      this.getStaffTypeList()
    }
  },
  computed: {
    ...mapGetters({
      staffTypeList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS.staffTypeList
    }),
    staffTypeListOptions () {
      const options = []
      if (this.staffTypeList && this.staffTypeList.length > 0) {
        this.staffTypeList.forEach((staffType) => {
          const option = {
            id: staffType.id,
            name: staffType.name,
            status: staffType.status
          }
          if (staffType.status === 0) {
            option.$isDisabled = true
          }
          options.push(option)
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getStaffTypeList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.ACTIONS.getStafftypelist
    }),
    selectStaffType (item) {
      this.$set(this.form, 'staff_type', item.id)
      this.$set(this.form, 'staff_type_name', item.name)
    },
    clearStaffType () {
      this.$set(this.form, 'staff_type', null)
      this.$set(this.form, 'staff_type_name', null)
    }
  }
}
</script>
