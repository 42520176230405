<template>
  <!-- Add/Edit Form -->
  <b-modal
    id="createOrgFromContact"
    ref="createOrgFromContact"
    :no-enforce-focus="true"
    :no-close-on-backdrop = 'true'
    size='lg'
    @hidden="createOrgFromContactOnHidden"
  >
    <div slot="modal-title">
      {{ title | translate }}
    </div>
    <b-form>
      <b-form-row>
        <form-thux-horizontal-select
            :validator="$v.form.organization"
            :label-form="'Organization' | translate"
            class-form="col-12 mb-2"
            labelColsSm="3"
            labelColsLg="3"
            label-search="text"
            :disabled="orgDisabled"
            :value="form.organization ? { value: form.organization, text: form.organization_name } : null"
            :placeholder="$t('Select an option')"
            :options="organizationListOptions"
            @select="selectOrganization"
            @clear="clearOrganization"
          />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-multiselect
          :validator="$v.form.products"
          :label-form="'Courses' | translate"
          label-form-class="mb-2"
          class-form="col-lg-12 mb-2 mt-2"
          label-search="text"
          labelColsSm="3"
          labelColsLg="3"
          :value="form.products"
          :options="productsOptions"
          :placeholder="
            $t('Search or select a {instance}', { instance: $t('course') })
          "
          @remove="removeProduct"
          @select="selectProduct"
          @search="onSearchProduct"
          @clear="$set(form, 'products', [])"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-datetime
          :validator="$v.form.max_date"
          :label-form="'Maximum usability date' | translate"
          labelColsSm="3"
          labelColsLg="3"
          class-form="col-lg-12 mb-2 mt-2"
          type="date"
          :value="maxDate"
          :placeholder="$t('Enter the date')"
          @change="setMaxDate"
        />
      </b-form-row>
    </b-form>
    <div slot="modal-footer">
      <b-btn class="mr-3" variant="default" @click.prevent="cancelOperation()">
        {{ 'Close' | translate }}
      </b-btn>
      <b-btn
        variant="primary"
        @click.prevent="closeAndSubscribeToCourses()"
        :disabled="$v.form.$invalid || form.products.length === 0 "
      >
        {{ 'OK' | translate }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { vue } from '@/main'
import { required } from 'vuelidate/lib/validators'
import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CONTACT_TYPES } from '../../../contact/store'
import { TYPES as ORGANIZATION_TYPES } from '../../../organization/store'
import { TYPES as PRODUCT_TYPES } from '../../../../product/product/store'

export default {
  name: 'SubscribeToCourseModal',
  props: ['contact', 'orgId'],
  mixins: [ThuxDetailPanelMixin],
  data () {
    return {
      form: {
        products: []
      },
      errors: {},
      maxDate: undefined
    }
  },
  components: {
  },
  created () {
    this.$set(this.form, 'organization', this.orgId)
    this.$set(this.form, 'products', [])
    this.setOrgFilters({ filter__status: 1, filter__organization_contacts__contact_id: this.contact.id, no_page: 'no_page' }).then(
      () => {
        console.log(this.orgId)
        console.log(this.orgList)
        if (this.orgId) {
          const org = this.orgList.find(item => item.id === parseInt(this.orgId))
          this.$set(this.form, 'organization', org.id)
          this.$set(this.form, 'organization_name', `${org.name} (${org.typology_name})`)
        }
      }
    )
  },
  mounted () {
    this.opencreateOrgFromContact()
  },
  computed: {
    title () {
      return `${this.$t('Subscribe')} ${this.contact.last_name}  ${this.contact.first_name} ${this.$t('to courses')}`
    },
    ...mapGetters({
      orgTypeChoices: CONTACT_TYPES.GENERIC.organization.contact.LIST.GETTERS.orgTypeChoices,
      orgList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.GETTERS.list,
      productList: PRODUCT_TYPES.GENERIC.product.product.LIST.GETTERS.list
    }),
    organizationListOptions () {
      const options = []
      if (this.orgList && this.orgList.length > 0) {
        this.orgList.forEach((instance) => {
          options.push({
            value: instance.id,
            text: `${instance.name} (${instance.typology_name})`
          })
        })
      }
      return options
    },
    productsOptions () {
      const options = []
      if (this.productList && this.productList.results) {
        this.productList.results.forEach((instance) => {
          options.push({
            value: instance.id,
            text: `(${instance.code}) ${instance.name}`,
            sku: instance.code
          })
        })
      }
      return options
    },
    orgDisabled () {
      if (this.orgId) {
        return true
      }
      return false
    }
  },
  methods: {
    init () {},
    ...mapActions({
      getOrgtypechoices: CONTACT_TYPES.GENERIC.organization.contact.LIST.ACTIONS.getOrgtypechoices,
      setOrgFilters: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.ACTIONS.setFilters,
      setProductFilters: PRODUCT_TYPES.GENERIC.product.product.LIST.ACTIONS.setFilters
    }),
    ...mapMutations({
      setOrgList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.MUTATIONS.setList,
      setProductsList: PRODUCT_TYPES.GENERIC.product.product.LIST.MUTATIONS.setList
    }),
    opencreateOrgFromContact () {
      this.$refs.createOrgFromContact.show()
    },
    createOrgFromContactOnHidden (evt) {
      this.$emit('close-modal')
    },
    cancelOperation (evt) {
      this.$emit('close-modal')
    },
    closeAndSubscribeToCourses (evt) {
      const formData = Object.assign({}, this.form)
      formData.contact = this.contact.id
      this.$emit('close-modal-and-subscribe', formData)
    },
    selectOrganization (item) {
      this.$set(this.form, 'organization', item.value)
      this.$set(this.form, 'organization_name', item.text)
    },
    clearOrganization () {
      this.$set(this.form, 'organization', null)
      this.$set(this.form, 'organization_name', null)
    },
    onSearchProduct (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        this.setProductFilters({ filter__name__icontains: search, filter__status: 1 }).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setProductsList({})
      }
    },
    removeProduct (item) {
      const products = this.form.products.filter((product) => {
        return product.value !== item.value
      })
      this.$set(this.form, 'products', products)
      if (this.form.products.length === 0) this.$set(this.form, 'products', null)
    },
    selectProduct (item) {
      if (!this.form.products) {
        this.$set(this.form, 'products', [])
      }
      this.form.products.push(item)
    },
    setMaxDate (evt) {
      const date = evt.date
      if (date) {
        this.$set(this.form, 'max_date', date.format('YYYY-MM-DD'))
      } else {
        this.$set(this.form, 'max_date', undefined)
      }
    }

  },
  validations: {
    form: {
      products: { required },
      organization: { required },
      max_date: { required }
    }
  }
}
</script>
