<template>
    <div class="nested-form mt-5" v-show="dpiList">
      <b-form>
        <b-form-row v-if="formEditable">
          <form-thux-horizontal-select
            :validator="$v.form.status"
            :external-errors="errors['status']"
            :label-form="'Status' | translate"
            class-form="col-lg-6 my-2"
            labelColsSm="4"
            labelColsLg="2"
            label-search="text"
            :value="form.status ? { value: form.status, text: form.status_name } : null"
            :placeholder="$t('Select status')"
            :options="documentStatusOptions"
            @select="selectDocumentStatus"
            @clear="clearDocumentStatus"
          />
          <form-thux-horizontal-file
            :validator="$v.form.attachment"
            :label-form="'Attachment' | translate"
            class-form="col-lg-6 my-2"
            labelColsSm="4"
            labelColsLg="2"
            :value="form.attachment"
            :placeholder="$t('Choose a file or drop it here...')"
            :drop-placeholder="$t('Drop file here...')"
            @input="$set(form, 'attachment', $event)"
          />
        </b-form-row>
        <b-form-row v-if="formEditable">
        <form-thux-horizontal-autocomplete
          :validator="$v.form.document"
          :external-errors="errors['document']"
          :label-form="'Document' | translate"
          label-search="text"
          class-form="col-lg-12 my-2"
          labelColsSm="2"
          labelColsLg="1"
          :value="form.document ? { value: form.document, text: form.document_name } : null"
          :placeholder="$t('Select document')"
          :placeholder-field="form.document_name"
          :options="documentOptions"
          @search="onSearchDocument"
          @select="selectDocument"
          @clear="clearDocument"
        />

        </b-form-row>
        <b-form-row v-if="formEditable">
          <form-thux-horizontal-datetime
            :validator="$v.form.date_start"
            :external-errors="errors['date_start']"
            :label-form="'Validity start date' | translate"
            class-form="col-lg-6 my-2"
            labelColsSm="4"
            labelColsLg="2"
            type="date"
            :value="date_start"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'date_start')"
          />
          <form-thux-horizontal-datetime
            :validator="$v.form.date_end"
            :external-errors="errors['date_end']"
            :label-form="'Validity end date' | translate"
            class-form="col-lg-6 my-2"
            labelColsSm="4"
            labelColsLg="2"
            type="date"
            :value="date_end"
            :min-date="form.date_start"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'date_end')"
          />
        </b-form-row>
        <b-form-row>
            <form-thux-horizontal-checkbox
              :validator="$v.form.dpi"
              v-for="dpi in dpiList" :key="dpi.id"
              :label-form="dpi.name"
              class-form="col-lg-4 mb-2"
              label-cols-lg="6"
              label-cols-sm="6"
              :value="form.dpi[dpi.id]"
              :disabled='!formEditable'
              @change="setDpiChange($event, dpi.id)"
            />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="formEditable ? cancel() : closeForm()">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          v-if="formEditable"
          variant="primary"
          @click.prevent="onSubmit()"
          :disabled="$v.form.$invalid">
          {{ 'Save' | translate }}
        </b-button>
        <b-button
          v-else
          variant="primary"
          @click.prevent="makeDpiFormEditable()">
          {{ 'Edit' | translate }}
        </b-button>
      </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { vue } from '@/main'
import { TYPES as CONTACT_DPI_TYPES } from './store'
import { TYPES as DPI_TYPES } from '../../miscellaneous/dpi/store'
import { TYPES as DOCUMENT_TYPES } from '../../document/document/store'
import { TYPES as UPLOAD_DOCUMENT_TYPES } from '../../document/upload-document/store'

import slugify from 'slugify'

import { storeMixin } from '@/storeMixin'
import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import DocumentMixin from '../../document/upload-document/mixins/DocumentMixin.vue'
import DocumentStatusMixin from '../../document/upload-document/mixins/DocumentStatusMixin'

export default {
  name: 'ContactDpiEdit',
  mixins: [ThuxDetailPanelMixin, DocumentMixin, DocumentStatusMixin],
  props: ['orgId', 'cntId'],
  data () {
    return {
      pageName: 'Contact Dpi Edit',
      form: {
        organization: this.orgId,
        contact: this.cntId,
        dpi: {}
      },
      date_start: null,
      date_end: null,
      selectedDoc: undefined
    }
  },
  computed: {
    ...mapGetters({
      ...CONTACT_DPI_TYPES.GENERIC.organization.contactdpi.LIST.GETTERS,
      ...CONTACT_DPI_TYPES.GENERIC.organization.contactdpi.DETAIL.GETTERS,
      uploadDocumentList: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.GETTERS.list,
      dpiList: DPI_TYPES.GENERIC.miscellaneous.dpi.LIST.GETTERS.list,
      documentsList: DOCUMENT_TYPES.GENERIC.document.document.LIST.GETTERS.list,
      documentStatusChoicesList: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.GETTERS.documentStatusChoices,
      uploadDocumentDetail: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.DETAIL.GETTERS.detail
    }),
    documentStatusOptions () {
      const options = []
      if (this.documentStatusChoicesList && this.documentStatusChoicesList.length > 0) {
        this.documentStatusChoicesList.forEach((documentStatus) => {
          if (documentStatus[0] !== 'R') {
            options.push({
              value: documentStatus[0],
              text: documentStatus[1]
            })
          }
        })
      }
      return options
    }
  },
  components: { },
  methods: {
    ...mapActions({
      ...CONTACT_DPI_TYPES.GENERIC.organization.contactdpi.LIST.ACTIONS,
      ...CONTACT_DPI_TYPES.GENERIC.organization.contactdpi.DETAIL.ACTIONS,
      setDpiFilters: DPI_TYPES.GENERIC.miscellaneous.dpi.LIST.ACTIONS.setFilters,
      uploadDocumentCreate: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.DETAIL.ACTIONS.create,
      uploadDocumentDuplicate: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.DETAIL.ACTIONS.duplicate,
      uploadDocumentUpdate: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.DETAIL.ACTIONS.update,
      setUploadDocumentFilters: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.ACTIONS.setFilters,
      getDocumentStatusChoicesList:
        UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.ACTIONS.getDocumentstatuschoices
    }),
    makeDpiFormEditable () {
      this.makeFormEditable()
    },
    changeName (evt) {
      this.$set(this.form, 'name', evt)
      if (this.formEditable) {
        this.setSlug()
      }
    },
    setSlug () {
      this.$v.form.name.$touch()
      if (this.form.name) {
        this.$set(this.form, 'slug', slugify(this.form.name, { remove: /[*+~.()'"!:@]/g }))
      } else {
        this.$set(this.form, 'slug', undefined)
      }
    },
    init () {
      this.cleanErrors()
      this.getDocumentStatusChoicesList()
      this.getDocumentList({ filter__status: 1, filter__subtype: 'DPI' })
      this.makeFormReadonly()
      const promises = [
        this.setDpiFilters({ filter__status: 1, no_page: 'no_page', order_by__name: 'name' }),
        this.setFilters({ filter__contact_id: this.cntId, filter__organization_id: this.orgId, no_page: 'no_page' })

      ]
      Promise.all(promises).then(
        () => {
          this.dpiList.forEach(el => {
            const item = this.list.find(item => item.dpi === el.id)
            if (item) {
              this.$set(this.form.dpi, el.id, true)
            } else {
              this.$set(this.form.dpi, el.id, false)
            }
          })
        }
      )
    },
    setDpiChange (evt, dpiId) {
      this.$set(this.form.dpi, dpiId, evt)
    },
    cancel () {
      this.makeFormReadonly()
    },
    selectDocument (item) {
      this.$set(this.form, 'document', item.value)
      this.$set(this.form, 'document_name', item.text)
      this.selectedDoc = this.documentsList.results.find(el => el.id === item.value)
      this.setUploadDocumentFilters({
        filter__document_id: item.value,
        filter__organization_id: this.orgId,
        filter__worker_id: this.cntId,
        filter__is_passed: 'False',
        no_page: 'no_page',
        order_by__: '-id'
      })
    },
    dateChange (evt, dateKey) {
      const date = evt.date
      if (date) {
        this.$set(this.form, dateKey, date.format('YYYY-MM-DD'))
        if (dateKey === 'date_start' && this.selectedDoc && this.selectedDoc.validity_duration) {
          const duration = this.convertStringToDuration(this.selectedDoc.validity_duration)
          if (duration.days || duration.hours) {
            let endDate = date.add(duration.days, 'days')
            endDate = endDate.add(duration.hours, 'hours')
            this.$set(this, 'date_end', endDate)
            this.$set(this.form, 'date_end', endDate.format('YYYY-MM-DD'))
          }
        }
      } else {
        this.$set(this.form, dateKey, undefined)
        this.$set(this, dateKey, undefined)
      }
    },
    convertStringToDuration (string) {
      if (string) {
        const numberOfHours = string.split(':')[0]
        const totalHoursList = numberOfHours.split(' ')
        let days = 0
        let hours = 0
        if (totalHoursList.length === 2) {
          days = parseInt(totalHoursList[0].trim())
          hours = parseInt(totalHoursList[1].trim())
        } else {
          days = Math.floor(parseInt(numberOfHours) / 24)
          const remainder = numberOfHours % 24
          hours = Math.floor(remainder)
        }
        return { days: days, hours: hours }
      }
      return {}
    },
    onSubmit () {
      if (this.uploadDocumentList.length > 0) {
        let formData = {
          organization: this.orgId,
          worker: this.cntId,
          is_from_config: false,
          status: this.form.status,
          attachment: this.form.attachment,
          document: this.form.document,
          date_start: this.form.date_start,
          date_end: this.form.date_end
        }
        if (!this.uploadDocumentList[0].attachment) {
          formData.id = this.uploadDocumentList[0].id
          formData = storeMixin.utils.parseFormMultipart(formData, ['attachment'])
          this.uploadDocumentUpdate(formData).then(
            () => {
              this.saveDpi()
            }
          )
        } else {
          this.uploadDocumentDuplicate({ id: this.uploadDocumentList[0].id }).then(
            () => {
              formData.id = this.uploadDocumentDetail.id
              formData = storeMixin.utils.parseFormMultipart(formData, ['attachment'])
              this.uploadDocumentUpdate(formData).then(
                () => {
                  this.saveDpi()
                }
              )
            }
          )
        }
      } else {
        let formData = {
          organization: this.orgId,
          worker: this.cntId,
          is_from_config: false,
          status: this.form.status,
          attachment: this.form.attachment,
          document: this.form.document,
          date_start: this.form.date_start,
          date_end: this.form.date_end
        }
        formData = storeMixin.utils.parseFormMultipart(formData, ['attachment'])
        this.uploadDocumentCreate(formData).then(
          () => {
            this.saveDpi()
          }
        )
      }
    },
    saveDpi () {
      const promises = []
      Object.keys(this.form.dpi).forEach(dpiId => {
        const item = this.list.find(el => el.dpi === parseInt(dpiId))
        if (!item) {
          if (this.form.dpi[dpiId]) {
            promises.push(this.create({
              organization: this.orgId,
              contact: this.cntId,
              dpi: dpiId,
              date_delivery: this.form.date_start
            }))
          }
        } else {
          if (this.form.dpi[dpiId]) {
            promises.push(this.update({
              id: item.id,
              organization: this.orgId,
              contact: this.cntId,
              dpi: dpiId,
              date_delivery: this.form.date_start

            }))
          } else {
            promises.push(this.delete({
              id: item.id
            }))
          }
        }
      })
      Promise.all(promises).then(
        this.makeFormReadonly()
      )
    },
    onSearchDocument (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        this.getDocumentList({ filter__name__icontains: search, filter__subtype: 'DPI', filter__status: 1 }).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setDocumentList({})
      }
    }
  },
  validations () {
    const vals = {
      form: {
        status: { required },
        attachment: { required },
        document: { required },
        date_start: { required },
        date_end: { },
        dpi: { }
      }
    }
    return vals
  }
}
</script>
