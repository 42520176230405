<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORGANIZATION_CONTACT_TYPES } from '../store'
import { vue } from '../../../../main'

export default {
  name: 'WorkplaceMixin',
  created () {
    this.setWorkplacelist([])
    if (this.form.workplace) {
      this.setWorkplacelist([])
    }
    // if (!this.workplaceList || (this.workplaceList && this.workplaceList.length === 0)) {
    //   this.getWorkplacelist()
    // }
  },
  computed: {
    ...mapGetters({
      workplaceList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS.workplaceList
    }),
    workplaceListOptions () {
      if (this.workplaceList) {
        return this.prepareListForSelect(this.workplaceList, 'id', ['name'])
      }
      return []
    }
  },
  methods: {
    ...mapMutations({
      setWorkplacelist: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.MUTATIONS.setWorkplacelist
    }),
    ...mapActions({
      getWorkplacelist: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.ACTIONS.getWorkplacelist
    }),
    selectWorkplace (item) {
      this.$set(this.form, 'work_place', item.value)
      this.$set(this.form, 'work_place_name', item.name)
    },
    clearWorkplace () {
      this.$set(this.form, 'work_place', null)
      this.$set(this.form, 'work_place_name', null)
    },
    onSearchWorkplace (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        filters.order_by = '-status'
        this.getWorkplacelist(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setWorkplacelist([])
        vue.$store.commit('componentLoading', true)
      }
    }

  }
}
</script>
