<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ORGANIZATION_CONTACT_TYPES } from '../store'

export default {
  name: 'JobRiskMixin',
  created () {
    if (!this.jobRiskList || (this.jobRiskList && this.jobRiskList.length === 0)) {
      this.getJobRiskList()
    }
  },
  computed: {
    ...mapGetters({
      jobRiskList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS.jobRiskList
    }),
    jobRiskListOptions () {
      const options = []
      if (this.jobRiskList && this.jobRiskList.length > 0) {
        this.jobRiskList.forEach((jobRisk) => {
          const option = {
            id: jobRisk.id,
            name: jobRisk.name,
            status: jobRisk.status
          }
          if (jobRisk.status === 0) {
            option.$isDisabled = true
          }
          options.push(option)
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getJobRiskList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.ACTIONS.getJobrisklist
    }),
    selectJobRisk (item) {
      this.$set(this.form, 'job_risk', item.id)
      this.$set(this.form, 'job_risk_name', item.name)
    },
    clearJobRisk () {
      this.$set(this.form, 'job_risk', null)
      this.$set(this.form, 'job_risk_name', null)
    }
  }
}
</script>
