<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as CONTACT_PHONE_TYPES } from '../store'

export default {
  name: 'TypologyMixin',
  created () {
    if (!this.typologyList || (this.typologyList && this.typologyList.length === 0)) {
      this.getTypologylist()
    }
  },
  computed: {
    ...mapGetters({
      typologyList: CONTACT_PHONE_TYPES.GENERIC.organization.contactphone.LIST.GETTERS.typologyList
    }),
    typologyListOptions () {
      const options = []
      if (this.typologyList && this.typologyList.length > 0) {
        this.typologyList.forEach((typology) => {
          const option = {
            id: typology.id,
            name: typology.name,
            status: typology.status
          }
          if (typology.status === 0) {
            option.$isDisabled = true
          }
          options.push(option)
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getTypologylist: CONTACT_PHONE_TYPES.GENERIC.organization.contactphone.LIST.ACTIONS.getTypologylist
    }),
    selectTypology (item) {
      this.$set(this.form, 'typology', item.id)
      this.$set(this.form, 'typology_name', item.name)
    },
    clearTypology () {
      this.$set(this.form, 'typology', null)
      this.$set(this.form, 'typology_name', null)
    }
  }
}
</script>
