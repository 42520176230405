<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORGANIZATION_CONTACT_TYPES } from './../store'
import { vue } from '../../../../main'

export default {
  name: 'ContactQualificationMixin',
  created () {
    this.setContactQualificationList([])
    if (this.form.contact_qualification) {
      this.setContactQualificationList({})
    }
  },
  computed: {
    ...mapGetters({
      contactQualificationList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS.contactQualificationList
    }),
    contactQualificationListOptions () {
      if (this.contactQualificationList) {
        return this.prepareListForMultiSelect(this.contactQualificationList.results, 'id', ['code', 'name'])
      }
      return []
    }
  },
  methods: {
    ...mapMutations({
      setContactQualificationList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.MUTATIONS.setContactqualificationlist
    }),
    ...mapActions({
      getContactQualificationList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.ACTIONS.getContactqualificationlist
    }),
    selectContactQualification (item) {
      this.$set(this.form, 'qualification', item.id)
      this.$set(this.form, 'qualification_str', item.name)
    },
    clearContactQualification () {
      this.$set(this.form, 'qualification', null)
      this.$set(this.form, 'qualification_str', null)
    },
    onSearchContactQualification (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.contact_qualification__icontains = search
        filters.order_by = '-status'
        this.getContactQualificationList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setContactQualificationList({})
        vue.$store.commit('componentLoading', true)
      }
    }
  }
}
</script>
