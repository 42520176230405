<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CONTACT_EMAIL_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../../components/thux-list/ThuxListMixin'

import ContactEmailTable from './ContactEmailTable'
import ContactEmailEdit from './ContactEmailEdit.vue'

export default {
  name: 'ContactEmailList',
  props: {
    cntId: { required: true }
  },
  mixins: [ThuxListMixin],
  components: {
    'component-table': ContactEmailTable,
    'component-edit': ContactEmailEdit
  },
  data () {
    return {
      title: this.$t('Contact emails'),
      rolePerm: ['organization_contactemail_list'],
      actionEnablePermission: ['organization_contactemail_enable'],
      actionDisablePermission: ['organization_contactemail_disable'],
      showHeader: false,
      showCommonFilters: false,
      searchFields: []
    }
  },
  computed: {
    ...mapGetters({
      ...CONTACT_EMAIL_TYPES.GENERIC.organization.contactemail.LIST.GETTERS
    }),
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_contactemail_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: CONTACT_EMAIL_TYPES.GENERIC.organization.contactemail.LIST.MUTATIONS.setSelectedList,
      setSelectAll: CONTACT_EMAIL_TYPES.GENERIC.organization.contactemail.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...CONTACT_EMAIL_TYPES.GENERIC.organization.contactemail.LIST.ACTIONS
    }),
    getComponentList () {
      this.setFilters({ filter__contact_id: this.cntId })
    },
    openEditForm (id) {
      this.$set(this.editParams, 'cntId', this.cntId)
      this.$set(this, 'componentId', id)
      this.$set(this, 'showEditForm', true)
      this.$set(this, 'showDetailForm', false)
    }
  }
}
</script>
