<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as CONTACT_EMAIL_TYPES } from '../store'

export default {
  name: 'EmailTypologyMixin',
  created () {
    if (!this.emailTypologyList || (this.emailTypologyList && this.emailTypologyList.length === 0)) {
      this.getEmailTypologyList()
    }
  },
  computed: {
    ...mapGetters({
      emailTypologyList: CONTACT_EMAIL_TYPES.GENERIC.organization.contactemail.LIST.GETTERS.emailTypologyList
    }),
    emailTypologyListOptions () {
      const options = []
      if (this.emailTypologyList && this.emailTypologyList.length > 0) {
        this.emailTypologyList.forEach((typology) => {
          const option = {
            id: typology.id,
            name: typology.name,
            status: typology.status
          }
          if (typology.status === 0) {
            option.$isDisabled = true
          }
          options.push(option)
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getEmailTypologyList: CONTACT_EMAIL_TYPES.GENERIC.organization.contactemail.LIST.ACTIONS.getEmailtypologylist
    }),
    selectEmailTypology (item) {
      this.$set(this.form, 'typology', item.id)
      this.$set(this.form, 'typology_name', item.name)
    },
    clearEmailTypology () {
      this.$set(this.form, 'typology', null)
      this.$set(this.form, 'typology_name', null)
    }
  }
}
</script>
