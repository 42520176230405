<template>
 <panel :title="title" noButton="true">
  <div>
    <b-form>
      <b-form-row>
        <form-thux-horizontal-input
          :validator="$v.form.last_name"
          :external-errors="errors['last_name']"
          :label-form="'Last name' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          :disabled="!formEditable"
          :value="form.last_name"
          @change="$set(form, 'last_name', $event)"
        />
        <form-thux-horizontal-input
          :validator="$v.form.first_name"
          :external-errors="errors['first_name']"
          :label-form="'First name' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          :disabled="!formEditable"
          :value="form.first_name"
          @change="$set(form, 'first_name', $event)"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-input
          :validator="$v.form.ssn"
          :external-errors="errors['ssn']"
          :label-form="'SSN' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          :disabled="!formEditable"
          :value="form.ssn"
          @change="$set(form, 'ssn', $event)"
        />
        <form-thux-horizontal-file
          :validator="$v.form.photo"
          :external-errors="errors['photo']"
          :label-form="'Photo' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          :value="form.photo"
          :disabled="!formEditable"
          :placeholder="photoPlaceholder ? photoPlaceholder : $t('Choose an image or drop it here...')"
          :drop-placeholder="$t('Drop image here...')"
          @input="$set(form, 'photo', $event)"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-datetime
          :validator="$v.form.birth_date"
          :external-errors="errors['birth_date']"
          :label-form="'Birth date' | translate"
          labelColsSm="4"
          labelColsLg="3"
          class-form="col-lg-6 mb-2 mt-2"
          type="date"
          :value="birthDate"
          :disabled="!formEditable"
          :placeholder="$t('Enter the date')"
          @change="birthDateChange($event)"
        />
        <form-thux-horizontal-input
          :validator="$v.form.birth_place"
          :external-errors="errors['birth_place']"
          :label-form="'Birth place' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          labelColsSm="4"
          labelColsLg="3"
          :disabled="!formEditable"
          :value="form.birth_place"
          @change="$set(form, 'birth_place', $event)"
        />
      </b-form-row>
    </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
      <b-button
        v-if="formEditable"
        class="mr-3"
        variant="default"
        @click.prevent="cancel()"
      >
        {{ 'Cancel' | translate }}
      </b-button>
      <b-button
        variant="primary"
        v-has-perms="editRolePerm"
        @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
        :disabled="$v.form.$invalid">
        {{ formEditable ? 'Save' : 'Edit' | translate }}
      </b-button>
    </div>
  </div>
 </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import ThuxDetailPanelMixin from '../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import moment from 'moment'

export default {
  name: 'ContactDetailForm',
  mixins: [ThuxDetailPanelMixin],
  props: {
    orgId: { required: true },
    errors: { required: true },
    orgType: { required: true },
    formInitial: {},
    photoPlaceholder: {},
    isEditable: {}
  },
  watch: {
    formInitial () {
      this.init()
    }
  },
  data () {
    return {
      form: {},
      birthDate: undefined,
      editRolePerm: 'organization_customercontact_update'
    }
  },
  computed: {
    title: function () {
      return this.$t('Contact Data')
    }
  },
  methods: {
    init () {
      this.$set(this, 'orgId', this.orgId)
      if (this.id && this.formInitial) {
        this.makeFormReadonly()
        this.setForm()
      } else {
        this.makeFormEditable()
      }
    },
    birthDateChange (evt) {
      const date = evt.date
      if (date) {
        this.$set(this.form, 'birth_date', date.format('YYYY-MM-DD'))
      } else {
        this.$set(this.form, 'birth_date', undefined)
      }
    },
    setForm () {
      const form = Object.assign({}, this.formInitial)
      this.$set(this, 'form', form)
      if (this.form.birth_date) {
        this.$set(this, 'birthDate', moment(new Date(form.birth_date)))
      }
    },
    cancel () {
      this.setForm()
      this.makeFormReadonly()
    },
    onSubmit () {
      const formData = Object.assign({}, this.form)
      if (this.orgId) {
        formData.organization = this.orgId
      }
      this.$emit('update-contact', { data: formData })
    }
  },
  validations: {
    form: {
      last_name: {
        required
      },
      first_name: {
        required
      },
      ssn: { },
      photo: { },
      birth_date: {},
      birth_place: {}
    }
  }
}
</script>
