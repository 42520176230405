<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORGANIZATION_CONTACT_TYPES } from '../store'
import { vue } from '../../../../main'

export default {
  name: 'OrganizationMixin',
  created () {
    this.setOrganizationlist([])
    if (this.form.contact) {
      this.setOrganizationlist({})
    }
  },
  computed: {
    ...mapGetters({
      organizationList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS.organizationList
    }),
    organizationListOptions () {
      const options = []
      if (this.organizationList && this.organizationList.results && this.organizationList.results.length > 0) {
        this.organizationList.results.forEach((organization) => {
          const option = {
            id: organization.id,
            name: organization.name,
            status: organization.status
          }
          if (organization.status === 0) {
            option.$isDisabled = true
          }
          options.push(option)
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setOrganizationlist: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.MUTATIONS.setOrganizationlist
    }),
    ...mapActions({
      getOrganizationList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.ACTIONS.getOrganizationlist
    }),
    onSearchOrganization (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        filters.exclude__contacts__in = this.form.contact
        filters.order_by = '-status.-date_create'
        this.getOrganizationList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setOrganizationlist({})
        vue.$store.commit('componentLoading', true)
      }
    },
    selectOrganization (item) {
      this.$set(this.form, 'organization', item.id)
      this.$set(this.form, 'organization_name', item.name)
    },
    clearOrganization () {
      if (!this.organizationList) {
        this.setOrganizationlist([])
      }
      const organizationList = this.organizationList.filter((organization) => {
        return organization.id !== this.form.organization
      })
      organizationList.push({
        id: this.form.organization,
        name: this.form.organization_name,
        status: 1
      })
      this.setOrganizationlist(organizationList)
      this.$set(this.form, 'organization', null)
      this.$set(this.form, 'organization_name', null)
    }
  }
}
</script>
