<template>
  <!-- Add/Edit Form -->
  <b-modal
    id="modalDuplicatedContact"
    ref="modalDuplicatedContact"
    :no-enforce-focus="true"
    :no-close-on-backdrop = 'true'
    size='lg'
    @hidden="modalDuplicatedContactOnHidden"
  >
    <div slot="modal-title">
      {{ title | translate }}
    </div>
    <div class="mt-3 mb-3">
      {{resultMessage}}
    </div>
    <div class="mb-3">
      {{message}}
    </div>
    <b-table
        no-local-sorting
        show-empty
        striped
        hover
        responsive
        :items="cntList"
        :fields="fields"
      >
      <template slot="cell(status)" slot-scope="row">
        <status-indicator :status="row.item.status" />
      </template>
      <template slot="cell(is_customer)" slot-scope="row">
        <status-indicator :status="row.item.is_customer" />
      </template>
      <template slot="cell(is_supplier)" slot-scope="row">
        <status-indicator :status="row.item.is_supplier" />
      </template>

      <template slot="cell(checks)" slot-scope="data">
        <thux-table-checks-component
          :select-all="false"
          :value="selectedItemId === data.item.id"
          @set-selected-row="setSelectedRow(data.item)"
        />
      </template>
    </b-table>
    <div class="mt-3">
      {{selectMessage}}
    </div>
    <div class="mt-3">
      {{questionMessage}}
    </div>
    <div slot="modal-footer">
      <b-btn class="mr-3" variant="default" @click.prevent="closeAndGoToForm()">
        {{ 'Change Data' | translate }}
      </b-btn>
      <b-btn
        v-if='orgId'
        variant="primary"
        @click.prevent="closeAndAddContactToOrg()"
        :disabled="!selectedItemId"
      >
        {{ 'OK' | translate }}
      </b-btn>
      <b-btn v-else
        variant="primary"
        @click.prevent="closeAndGoToDetail()"
        :disabled="!selectedItemId"
      >
        {{ 'OK' | translate }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as CNT_TYPES } from '../../../contact/store'
import StatusIndicator from '@/components/StatusIndicator'
import ThuxTableChecksComponent from '@/components/thux-table/thux-table-checks/ThuxTableChecksComponent'
import { ORGANIZATION_CUSTOMER_TYPE_CODE, ORGANIZATION_SUPPLIER_TYPE_CODE, ORGANIZATION_CUSTOMER_LEAD_CODE } from '@/const'

export default {
  name: 'DuplicatedContactModal',
  props: ['cntFilters', 'orgType', 'cntId'],
  data () {
    return {
      fields: [
        {
          key: 'checks',
          label: this.$t('')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: false,
          value: 'status'
        },
        {
          key: 'last_name',
          label: this.$t('Last name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: false,
          value: 'last_name'
        },
        {
          key: 'first_name',
          label: this.$t('First name'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: false,
          value: 'first_name'
        },
        {
          key: 'ssn',
          label: this.$t('SSN'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: false,
          value: 'ssn'
        },
        {
          key: 'organization_names',
          label: this.$t('Organization'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: false,
          value: 'organization_names'
        }
      ],
      selectedItemId: undefined,
      selectedOrgTypes: [],
      orgId: this.$route.params.id
    }
  },
  components: {
    StatusIndicator,
    ThuxTableChecksComponent
  },
  created () {
    const filters = {}
    Object.keys(this.cntFilters).forEach(key => {
      filters[`filter__${key}__iexact`] = this.cntFilters[key]
    })
    filters.no_page = 'no_page'
    if (this.cntId) {
      filters.exclude__id = this.cntId
    }
    this.setCntFilters(filters)
  },
  mounted () {
    this.openmodalDuplicatedContact()
  },
  computed: {
    ...mapGetters({ list: CNT_TYPES.GENERIC.organization.contact.LIST.GETTERS.list }),
    title () {
      return this.$t('Contact already exist')
    },
    message () {
      let msg = `${this.$t('Contact')} ${this.cntFilters.last_name} ${this.cntFilters.first_name}`
      if (this.cntFilters.ssn) {
        msg = `${msg}, ${this.$t('ssn')} ${this.cntFilters.ssn}`
      }
      msg = `${msg} ${this.$t('already present in the system')}`
      return msg
    },
    resultMessage () {
      return `${this.$t('It is not possible')} ${this.cntId ? this.$t('to edit the contact') : this.$t('to create a new contact')} ${this.$t('with the data entered')}.`
    },
    questionMessage () {
      const neededKeys = ['last_name', 'ssn', 'first_name']
      if (neededKeys.every(key => Object.keys(this.cntFilters).includes(key))) {
        return `${this.$t('If not, if you want to change the data and')} ${this.cntId ? this.$t('edit the contact') : this.$t('create a new contact')}, ${this.$t('press the "Change Data" button')}.`
      }
      if (!('ssn' in this.cntFilters)) {
        return `${this.$t('If not, SSN is not inserted')}. ${this.$t('If you want to specify it and')} ${this.cntId ? this.$t('edit the contact') : this.$t('create a new contact')}, ${this.$t('press the "Change Data" button')}.`
      }
      return ''
    },
    cntList () {
      if (Array.isArray(this.list)) {
        return this.list
      }
      return []
    },
    selectMessage () {
      if (this.$route.params.id) {
        return this.$t('If the contact you want to add is present in the previous list, select it and press the OK button to add it to organization.')
      }
      return this.$t('If the contact you want to add is present in the previous list, select it and press the OK button to go to detail page.')
    }
  },
  methods: {
    ...mapActions({
      setCntFilters: CNT_TYPES.GENERIC.organization.contact.LIST.ACTIONS.setFilters
    }),
    openmodalDuplicatedContact () {
      this.$refs.modalDuplicatedContact.show()
    },
    modalDuplicatedContactOnHidden (evt) {
      this.$emit('close-modal')
    },
    closeAndGoToForm (evt) {
      this.$emit('close-modal')
    },
    setSelectedRow (item) {
      if (item.id === this.selectedItemId) {
        this.selectedItemId = undefined
        this.selectedOrgTypes = []
      } else {
        this.selectedItemId = item.id
        if (item.is_subject) {
          this.selectedOrgTypes = []
        } else if (item.is_lead) {
          this.selectedOrgTypes.push(ORGANIZATION_CUSTOMER_LEAD_CODE)
        } else {
          if (item.is_customer) {
            this.selectedOrgTypes.push(ORGANIZATION_CUSTOMER_TYPE_CODE)
          }
          if (item.is_supplier) {
            this.selectedOrgTypes.push(ORGANIZATION_SUPPLIER_TYPE_CODE)
          }
        }
      }
    },
    closeAndGoToDetail (evt) {
      this.$emit('close-modal-and-change', { cntId: this.selectedItemId, orgTypes: this.selectedOrgTypes })
    },
    closeAndAddContactToOrg (evt) {
      this.$emit('close-modal-and-add-contact', { cntId: this.selectedItemId })
    }
  }
}
</script>
