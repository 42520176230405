<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.form.typology"
            :external-errors="errors['typology']"
            :label-form="'Typology' | translate"
            class-form="col-12 mb-2"
            labelColsSm="4"
            labelColsLg="3"
            :disabled="!formEditable"
            :customize-option="true"
            :label-search="'name'"
            :value="form.typology ? { value: form.typology, name: form.typology_name } : null"
            :options="emailTypologyListOptions"
            :placeholder="$t('Type a typology')"
            :placeholder-field="form.typology_name"
            @select="selectEmailTypology"
            @clear="clearEmailTypology"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.email"
            :external-errors="errors['email']"
            :label-form="'Email' | translate"
            class-form="col-12 mb-2"
            labelColsSm="4"
            labelColsLg="3"
            type="email"
            :value="form.email"
            :disabled="!formEditable"
            @change="$set(form, 'email', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-checkbox
            :validator="$v.form.is_primary"
            :external-errors="errors['is_primary']"
            :label-form="'Primary Email' | translate"
            label-form-class="mt-2"
            class="col-12 mb-2"
            labelColsSm="4"
            labelColsLg="3"
            :value="form.is_primary"
            :disabled="!formEditable"
            @change="$set(form, 'is_primary', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-checkbox
            :validator="$v.form.is_for_training"
            :external-errors="errors['is_for_training']"
            :label-form="'Training Email' | translate"
            label-form-class="mt-2"
            class="col-12 mb-2"
            labelColsSm="4"
            labelColsLg="3"
            :value="form.is_for_training"
            :disabled="!formEditable"
            @change="$set(form, 'is_for_training', $event)"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="formEditable ? cancel() : closeForm()">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          variant="primary"
          v-has-perms="editRolePerm"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
          :disabled="$v.form.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as CONTACT_EMAIL_TYPES } from './store'

import ThuxDetailPanelMixin from '../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import TypologyMixin from './mixins/EmailTypologyMixin'

export default {
  name: 'ContactEmailEdit',
  props: {
    editParams: { default: {} }
  },
  mixins: [ThuxDetailPanelMixin, TypologyMixin],
  data () {
    return {
      pageName: 'Contact email',
      cntId: undefined,
      rolePerm: 'organization_contactemail_retrieve',
      editRolePerm: 'organization_contactemail_update'
    }
  },
  computed: {
    ...mapGetters({
      ...CONTACT_EMAIL_TYPES.GENERIC.organization.contactemail.DETAIL.GETTERS
    })
  },
  methods: {
    ...mapActions({
      ...CONTACT_EMAIL_TYPES.GENERIC.organization.contactemail.DETAIL.ACTIONS,
      getList: CONTACT_EMAIL_TYPES.GENERIC.organization.contactemail.LIST.ACTIONS.getList
    }),
    initFields () {
      this.$set(this.form, 'contact', this.editParams.cntId)
    },
    onSubmit () {
      const formData = this.getFormData()
      const promise = this.id ? this.update(formData) : this.create(formData)
      promise.then(
        () => {
          setTimeout(() => {
            this.getList()
            if (!this.id) {
              this.closeForm()
            } else {
              this.makeFormReadonly()
            }
          }, 500)
        }
      )
    },
    setForm () {
      const form = Object.assign({}, this.detail)
      if (form.typology) {
        form.typology_name = this.detail.typology.name
        form.typology = this.detail.typology.id
      }
      this.$set(this, 'form', form)
    }
  },
  validations: {
    form: {
      contact: {
        required
      },
      typology: {
        required
      },
      email: {
        required, email
      },
      is_primary: {},
      is_for_training: {}
    }
  }
}
</script>
