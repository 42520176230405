<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            v-if="!orgId"
            :validator="$v.form.organization"
            :external-errors="errors['organization']"
            :label-form="'Organization' | translate"
            class-form="col-lg-6 mb-3"
            :disabled="!formEditable"
            :customize-option="true"
            labelColsSm="4"
            labelColsLg="2"
            :label-search="'name'"
            :value="form.organization ? { value: form.organization, name: form.organization_name } : null"
            :options="organizationListOptions"
            :placeholder="$t('Search an organization')"
            :placeholder-field="form.organization_name"
            @search="onSearchOrganization"
            @select="selectOrganization"
            @clear="clearOrganization"
          />
          <form-thux-horizontal-multiselect
            :validator="$v.form.roles"
            label-form="Roles"
            label-form-class="mb-2"
            :class-form="orgId ? 'col-lg-12 mb-3' : 'col-lg-6 mb-3'"
            :labelColsSm="orgId ? 2 : 4"
            :labelColsLg="orgId ? 1 : 2"
            :external-errors="errors['roles']"
            label-search="name"
            :disabled="!formEditable"
            :value="form.roles"
            :options="rolesOptions"
            :placeholder="
              $t('Select a role')
            "
            @remove="removeRole"
            @select="selectRole"
            @clear="$set(form, 'roles', null)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.form.staff_type"
            :external-errors="errors['staff_type']"
            :label-form="'Staff type' | translate"
            class-form="col-lg-6 mb-3"
            :disabled="!formEditable"
            :customize-option="true"
            labelColsSm="4"
            labelColsLg="2"
            :label-search="'name'"
            :value="form.staff_type ? { value: form.staff_type, name: form.staff_type_name } : null"
            :options="staffTypeListOptions"
            :placeholder="$t('Type a staff type')"
            :placeholder-field="form.staff_type_name"
            @select="selectStaffType"
            @clear="clearStaffType"
          />
          <form-thux-horizontal-autocomplete
            :validator="$v.form.contract_type"
            :external-errors="errors['contract_type']"
            :label-form="'Contract typology' | translate"
            class-form="col-lg-6 mb-3"
            :disabled="!formEditable"
            :customize-option="true"
            labelColsSm="4"
            labelColsLg="2"
            :label-search="'name'"
            :value="form.contract_type ? { value: form.contract_type, name: form.contract_type_name } : null"
            :options="contractTypeListOptions"
            :placeholder="$t('Type a contract type')"
            :placeholder-field="form.contract_type_name"
            @select="selectContractType"
            @clear="clearContractType"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-datetime
            :validator="$v.form.contract_start"
            :external-errors="errors['contract_start']"
            :label-form="'Contract start' | translate"
            :disabled="!formEditable"
            class-form="col-lg-6 mb-3"
            labelColsSm="4"
            labelColsLg="2"
            type="date"
            :value="contractStart"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'contract_start')"
          />
          <form-thux-horizontal-datetime
            :validator="$v.form.contract_end"
            :external-errors="errors['contract_end']"
            :label-form="'Contract end' | translate"
            :disabled="!formEditable"
            class-form="col-lg-6 mb-3"
            labelColsSm="4"
            labelColsLg="2"
            type="date"
            :value="contractEnd"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'contract_end')"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.form.job_risk"
            :external-errors="errors['job_risk']"
            :label-form="'Job risk' | translate"
            class-form="col-lg-6 mb-3"
            :disabled="!formEditable"
            :customize-option="true"
            labelColsSm="4"
            labelColsLg="2"
            :label-search="'name'"
            :value="form.job_risk ? { value: form.job_risk, name: form.job_risk_name } : null"
            :options="jobRiskListOptions"
            :placeholder="$t('Type a job risk')"
            :placeholder-field="form.job_risk_name"
            @select="selectJobRisk"
            @clear="clearJobRisk"
          />
          <form-thux-horizontal-autocomplete
            :validator="$v.form.work_place"
            :external-errors="errors['work_place']"
            :label-form="'Work place' | translate"
            class-form="col-lg-6 mb-3"
            :disabled="!formEditable"
            labelColsSm="4"
            labelColsLg="2"
            :label-search="'name'"
            :value="form.work_place ? { value: form.work_place, name: form.work_place_name } : null"
            :options="workplaceListOptions"
            :placeholder="$t('Type a work place')"
            :placeholder-field="form.work_place_name"
            @select="selectWorkplace"
            @search="onSearchWorkplace"
            @clear="clearWorkplace"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.form.qualification"
            :external-errors="errors['qualification']"
            :label-form="'Contact qualification' | translate"
            class-form="col-lg-6 mb-3"
            :disabled="!formEditable"
            :customize-option="true"
            labelColsSm="4"
            labelColsLg="2"
            :label-search="'name'"
            :value="form.qualification ? { value: form.qualification, name: form.qualification_str } : null"
            :options="contactQualificationListOptions"
            :placeholder="$t('Type a contact qualification')"
            :placeholder-field="form.qualification_str"
            @select="selectContactQualification"
            @search="onSearchContactQualification"
            @clear="clearContactQualification">
          </form-thux-horizontal-autocomplete>
          <form-thux-horizontal-datetime
            :validator="$v.form.date_next_medical_exam"
            :external-errors="errors['date_next_medical_exam']"
            :label-form="'Next medical exam' | translate"
            :disabled="!formEditable"
            class-form="col-lg-6 mb-3"
            labelColsSm="4"
            labelColsLg="2"
            type="date"
            :value="nextMedicalExam"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'date_next_medical_exam')"
          />
        </b-form-row>
        <hr>
        <organization-contact-security-roles-manager
          :form-editable-update="formEditable"
          :org-cnt-security-roles="contactSecurityRolesList"
          :organization="form.organization"
          :contact="contact"
          :reset-contact-security-roles="resetContactSecurityRoles"
          @update-security-roles="updateOrganizationContactSecurityRoles"
        />
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-between">
        <div>
          <b-button
            v-if='orgId || this.id'
            variant="primary"
            v-has-perms="agreeDocumentRolePerm"
            class="mr-3"
            @click.prevent="openDocumentForm('agree')">
            {{ 'Upload UNILAV' | translate }}
          </b-button>
          <b-button
            v-if='orgId || this.id'
            variant="primary"
            v-has-perms="medDocumentRolePerm"
            class="mr-3"
            @click.prevent="openDocumentForm('med')">
            {{ 'Upload medical doc' | translate }}
          </b-button>
          <b-button
            v-if='orgId || this.id'
            variant="primary"
            v-has-perms="DPIRolePerm"
            class="mr-3"
            @click.prevent="openDPIForm()">
            {{ 'Upload DPI' | translate }}
          </b-button>
          <b-button
            v-if='orgId || this.id'
            variant="primary"
            v-has-perms="printWorkerCardRolePerm"
            class="mr-3"
            @click="printWorkerCard()">
            {{ 'print card' | translate }}
          </b-button>
          <b-button
            v-if='orgId || this.id'
            variant="primary"
            v-has-perms="printDPIDeliveryRolePerm"
            class="mr-3"
            @click.prevent="printDPIDelivery()">
            {{ 'print dpi delivery' | translate }}
          </b-button>
        </div>
        <div>
          <b-button
            v-if="!orgId"
            class="mr-3"
            variant="default"
            @click.prevent="formEditable ? cancel() : closeForm()">
            {{ formEditable ? 'Cancel' : 'Close' | translate }}
          </b-button>
          <b-button
            v-else-if="formEditable && orgId"
            class="mr-3"
            variant="default"
            @click.prevent="cancel()"
          >
            {{ 'Cancel' | translate }}
          </b-button>
          <b-button
            variant="primary"
            v-has-perms="editRolePerm"
            @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
            :disabled="$v.form.$invalid || $v.formContactSecurityRole.$invalid">
            {{ formEditable ? 'Save' : 'Edit' | translate }}
          </b-button>
        </div>
      </div>
      <contact-dpi-edit
        v-if="showDPIForm"
        :orgId="organization"
        :cntId="contact"
        v-on:close-form="showDPIForm=false"/>
      <organization-contact-upload-document
        v-if="showDocumentForm"
        :orgId="organization"
        :cntId="contact"
        :dateStart="contractStart"
        :dateEnd="contractEnd"
        :nextMedicalExam="nextMedicalExam"
        :docType="docType"
        v-on:close-form="closeDocumentUploadForm()"/>
    </div>
  </panel>
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { TYPES as ORGANIZATION_CONTACT_TYPES } from './store'
import { TYPES as ORGANIZATION_CONTACT_SECURITY_ROLES_TYPES } from '../organization-contact-security-role/store'
import moment from 'moment'
import { checkIfEqual } from '@/const'

import ThuxDetailPanelMixinVue from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import ContractTypeMixin from './mixins/ContractTypeMixin'
import JobRiskMixin from './mixins/JobRiskMixin'
import OrganizationMixin from './mixins/OrganizationMixin'
import RolesMixin from './mixins/RolesMixin'
import StaffTypeMixin from './mixins/StaffTypeMixin'
import WorkplaceMixin from './mixins/WorkplaceMixin.vue'
import ContactQualificationMixin from './mixins/ContactQualificationMixin'
import PopulateSelectMixin from '@/components/PopulateSelectMixin'

import ContactDpiEdit from '../contact-dpi/ContactDpiEdit'
import OrganizationContactUploadDocument from './component/OrganizationContactUploadDocument'
import OrganizationContactSecurityRolesManager from './component/organization-contact-security-roles/OrganizationContactSecurityRolesManager'

export default {
  name: 'OrganizationContactEdit',
  mixins: [
    PopulateSelectMixin,
    ThuxDetailPanelMixinVue,
    ContractTypeMixin,
    JobRiskMixin,
    OrganizationMixin,
    RolesMixin,
    StaffTypeMixin,
    WorkplaceMixin,
    ContactQualificationMixin
  ],
  props: {
    cntId: {},
    orgId: {}
  },
  data () {
    return {
      dateOptions: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        locale: 'it'
      },
      pageName: 'Organization Contact',
      contractStart: undefined,
      contractEnd: undefined,
      nextMedicalExam: undefined,
      organizationcntId: undefined,
      showDPIForm: false,
      showDocumentForm: false,
      docType: undefined,

      // FORM SECURITY ROLE
      formContactSecurityRole: [],
      formContactSecurityRoleInitial: [],
      securityRoleToDelete: new Set(),
      securityRoleToChange: new Set(),
      isFormContactSecurityRoleChanged: false,
      initializeFormContactSecurityRole: false,
      resetContactSecurityRoles: false,

      // Permissions
      agreeDocumentRolePerm: 'document_uploaddocument_create',
      medDocumentRolePerm: 'document_uploaddocument_create',
      DPIRolePerm: 'document_uploaddocument_create',
      printWorkerCardRolePerm: 'organization_organizationcontact_print_card_pdf',
      printDPIDeliveryRolePerm: 'organization_organizationcontact_print_dpi_delivery_pdf',
      rolePerm: 'organization_organizationcontact_retrieve',
      editRolePerm: 'organization_organizationcontact_update'
    }
  },
  computed: {
    ...mapGetters({
      ...ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.DETAIL.GETTERS,
      contactSecurityRolesList: ORGANIZATION_CONTACT_SECURITY_ROLES_TYPES.GENERIC.organization.organizationcontactsecurityrole.LIST.GETTERS.list
    }),
    title: function () {
      if (this.id || this.orgId) {
        if (this.formEditable) {
          return `${this.$t('Edit')} ${this.$t(this.pageName).toLowerCase()}`
        } else {
          return `${this.$t('Show')} ${this.$t(this.pageName).toLowerCase()}`
        }
      } else {
        return `${this.$t('Add')} ${this.$t(this.pageName).toLowerCase()}`
      }
    },
    organization () {
      if (this.orgId) {
        return this.orgId
      }
      if (this.organizationContact && this.organizationContact.organization) {
        return this.organizationContact.organization.id
      }
      return undefined
    },
    contact () {
      if (this.cntId) {
        return this.cntId
      }
      if (this.editParams.cntId) {
        return this.editParams.cntId
      }
      if (this.organizationContact && this.organizationContact.contact) {
        return this.organizationContact.contact.id
      }
      return undefined
    }
  },
  components: {
    ContactDpiEdit,
    OrganizationContactUploadDocument,
    OrganizationContactSecurityRolesManager
  },
  methods: {
    ...mapActions({
      ...ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.DETAIL.ACTIONS,
      getList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.ACTIONS.getList,
      // SECURITY ROLES
      setContactSecurityRolesFilters: ORGANIZATION_CONTACT_SECURITY_ROLES_TYPES.GENERIC.organization.organizationcontactsecurityrole.LIST.ACTIONS.setFilters,
      createContactSecurityRoles: ORGANIZATION_CONTACT_SECURITY_ROLES_TYPES.GENERIC.organization.organizationcontactsecurityrole.DETAIL.ACTIONS.create,
      updateContactSecurityRoles: ORGANIZATION_CONTACT_SECURITY_ROLES_TYPES.GENERIC.organization.organizationcontactsecurityrole.DETAIL.ACTIONS.update,
      deleteContactSecurityRoles: ORGANIZATION_CONTACT_SECURITY_ROLES_TYPES.GENERIC.organization.organizationcontactsecurityrole.DETAIL.ACTIONS.delete,
      cleanAllDataContactSecurityRoles: ORGANIZATION_CONTACT_SECURITY_ROLES_TYPES.GENERIC.organization.organizationcontactsecurityrole.LIST.ACTIONS.cleanAllData
    }),
    init () {
      this.cleanErrors()
      this.cleanAllDataContactSecurityRoles()
      this.setOrganizationlist([])
      this.$set(this, 'docType', undefined)
      this.$set(this, 'showDPIForm', undefined)
      this.$set(this, 'showDocumentForm', undefined)
      this.$set(this, 'contractStart', undefined)
      this.$set(this, 'contractEnd', undefined)
      this.$set(this, 'nextMedicalExam', undefined)
      this.$set(this.form, 'organization', this.orgId)
      this.$set(this.form, 'contact', this.cntId)
      if (this.orgId) {
        this.getOrganizationcontact({ orgId: this.orgId, cntId: this.cntId }).then(
          () => {
            if (this.organizationContact.id) {
              this.$set(this, 'organizationcntId', this.organizationContact.id)
              this.makeFormReadonly()
              this.setForm()
            } else {
              this.makeFormReadonly()
            }
          }
        )
        this.getContactSecurityRoles(this.orgId, this.cntId)
      } else if (this.id) {
        this.retrieve({ id: this.id }).then(
          () => {
            this.getContactSecurityRoles(this.detail.organization.id, this.detail.contact)
            this.makeFormReadonly()
            this.setForm()
            this.getOrganizationList({ no_page: 'no_page', exclude__contacts__in: this.form.contact })
          }
        )
      } else {
        this.$set(this.form, 'contact', this.editParams.cntId)
        this.makeFormEditable()
      }
    },
    getContactSecurityRoles (orgId, cntId) {
      this.setContactSecurityRolesFilters({ filter__organization_id: orgId, filter__contact__id: cntId, no_page: 'no_page' }).then(() => {
        this.$set(this, 'formContactSecurityRole', cloneDeep(this.contactSecurityRolesList))
        this.$set(this, 'formContactSecurityRoleInitial', cloneDeep(this.contactSecurityRolesList))
        this.$set(this, 'isFormContactSecurityRoleChanged', false)
        this.$set(this, 'initializeFormContactSecurityRole', true)
      })
    },
    setForm () {
      const form = Object.assign({}, this.organizationContact)
      if (form.organization) {
        this.$set(form, 'organization_name', this.organizationContact.organization.name)
        this.$set(form, 'organization', this.organizationContact.organization.id)
      }
      if (form.contract_start) {
        this.$set(this, 'contractStart', moment(form.contract_start))
      }
      if (form.contract_end) {
        this.$set(this, 'contractEnd', moment(form.contract_end))
      }
      if (form.date_next_medical_exam) {
        this.$set(this, 'nextMedicalExam', moment(form.date_next_medical_exam))
      }
      if (form.staff_type) {
        this.$set(form, 'staff_type_name', this.organizationContact.staff_type.name)
        this.$set(form, 'staff_type', this.organizationContact.staff_type.id)
      }
      if (form.contract_type) {
        this.$set(form, 'contract_type_name', this.organizationContact.contract_type.name)
        this.$set(form, 'contract_type', this.organizationContact.contract_type.id)
      }
      if (form.job_risk) {
        this.$set(form, 'job_risk_name', this.organizationContact.job_risk.name)
        this.$set(form, 'job_risk', this.organizationContact.job_risk.id)
      }
      this.$set(this, 'form', form)
    },
    dateChange (evt, fieldName) {
      const date = evt.date
      if (date) {
        this.$set(this.form, fieldName, date.format('YYYY-MM-DD'))
      } else {
        this.$set(this.form, fieldName, null)
      }
    },
    cancel () {
      if (!this.id && !this.orgId) {
        this.closeForm()
        return
      }
      this.setForm()
      this.makeFormReadonly()
      if (this.form.roles) {
        this.$set(this.form, 'roles', this.getRoles(this.form))
      }
      this.$set(this, 'resetContactSecurityRoles', !this.resetContactSecurityRoles)
    },
    onSubmit () {
      const promises = []
      const formContactSecurityRoleData = Object.assign([], this.formContactSecurityRole)
      if (this.securityRoleToDelete && this.securityRoleToDelete.size > 0) {
        this.securityRoleToDelete.forEach((id) => {
          promises.push(this.deleteContactSecurityRoles({ id: id }))
        })
      }
      formContactSecurityRoleData.forEach((form) => {
        if (!form.id) {
          promises.push(this.createContactSecurityRoles(form))
        } else {
          if (this.securityRoleToChange.has(form.id)) {
            promises.push(this.updateContactSecurityRoles(form))
          }
        }
      })
      if (!this.$v.$invalid) {
        let formData = Object.assign({}, this.form)
        formData = this.setRoles(formData)
        const promise = this.organizationcntId || this.id ? this.update(formData) : this.create(formData)
        promise.then(() => {
          this.$set(this, 'organizationcntId', this.organizationContact.id)
          console.log(this.isFormContactSecurityRoleChanged)
          if (this.isFormContactSecurityRoleChanged) {
            Promise.all(promises).then(() => {
              this.cancel()
              this.getList()
            })
          } else {
            this.cancel()
          }
        })
      }
    },
    openDPIForm () {
      this.$set(this, 'showDPIForm', !this.showDPIForm)
      this.$set(this, 'showDocumentForm', false)
      this.$set(this, 'docType', undefined)
    },
    openDocumentForm (docType) {
      if (docType === this.docType) {
        this.$set(this, 'showDocumentForm', false)
        this.$set(this, 'docType', undefined)
      } else {
        this.$set(this, 'showDocumentForm', true)
        this.$set(this, 'docType', docType)
      }
      this.$set(this, 'showDPIForm', false)
    },
    closeDocumentUploadForm () {
      this.$set(this, 'showDocumentForm', false)
      this.$set(this, 'docType', undefined)
    },
    updateOrganizationContactSecurityRoles (securityRoles, securityRoleToDelete, securityRoleToChange) {
      const formSecurityRoles = securityRoles
      this.$set(this, 'formContactSecurityRole', formSecurityRoles)
      this.$set(this, 'securityRoleToDelete', securityRoleToDelete)
      this.$set(this, 'securityRoleToChange', securityRoleToChange)
      this.$set(this, 'isFormContactSecurityRoleChanged', false)
      const initial = this.formContactSecurityRoleInitial
      const a = checkIfEqual(formSecurityRoles, initial)
      if (!a || formSecurityRoles.length !== this.formContactSecurityRoleInitial.length) {
        this.$set(this, 'isFormContactSecurityRoleChanged', true)
      }
      console.log(this.isFormContactSecurityRoleChanged)
    },
    printWorkerCard () {
      const fileName = `${this.organizationContact.contact_last_name}_${this.organizationContact.contact_first_name}_${this.organizationContact.organization.name}.pdf`
      this.download({ id: this.organizationContact.id, action: 'print-card-pdf', simpleFileName: fileName })
    },
    printDPIDelivery () {
      const fileName = `DPI_${this.organizationContact.contact_last_name}_${this.organizationContact.contact_first_name}_${this.organizationContact.organization.name}.pdf`
      this.download({ id: this.organizationContact.id, action: 'print-dpi-delivery-pdf', simpleFileName: fileName })
    }
  },
  validations: {
    form: {
      organization: {
        required
      },
      contact: {
        required
      },
      roles: { },
      staff_type: { },
      contract_type: { },
      job_risk: { },
      contract_start: { },
      contract_end: { },
      work_place: { },
      qualification: { },
      date_next_medical_exam: { },
      date_nomination_sec_role: { }
    },
    formContactSecurityRole: {
      $each: {
        security_role: { required },
        contact: { required },
        organization: { required },
        date_nomination_sec_role: { }
      }
    }
  }
}
</script>
